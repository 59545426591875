import { gql } from '@apollo/client';

import { userFragment } from '../fragments/auth';
import { pageInfo } from '../fragments/pageInfo';

export const getUserDetailsQuery = gql`
  ${userFragment}
  query UserDetails {
    me {
      ...User
      wishlist {
        id
        productIds
      }
      metadata {
        key
        value
      }
    }
  }
`;

export const ordersQuery = gql`
  ${pageInfo}
  query Orders($first: Int!, $after: String) {
    me @connection(key: "orderList") {
      id
      orders(first: $first, after: $after) {
        edges {
          node {
            number
            created
            status
            paymentStatus
            total {
              gross {
                currency
                amount
              }
              net {
                currency
                amount
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
`;
