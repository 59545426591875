import { mode } from '@chakra-ui/theme-tools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function baseStyle(props: Record<string, any>) {
  const hoverBg = mode(`blackAlpha.100`, `whiteAlpha.100`)(props);
  const activeBg = mode(`blackAlpha.200`, `whiteAlpha.200`)(props);

  return {
    borderRadius: 'none',
    transition: 'all 0.2s',
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _hover: { bg: hoverBg, boxShadow: 'none' },
    _active: { bg: activeBg, boxShadow: 'none' },
    _focus: {
      boxShadow: 'none',
    },
  };
}

const sizes = {
  lg: {
    w: '40px',
    h: '40px',
    fontSize: '16px',
  },
  md: {
    w: '32px',
    h: '32px',
    fontSize: '12px',
  },
  sm: {
    w: '24px',
    h: '24px',
    fontSize: '10px',
  },
};

const defaultProps = {
  size: 'md',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
