import { CheckoutStep } from 'components/checkout/progress-bar';

export const WINDOW_EXISTS = typeof window !== 'undefined';
export const LOCAL_STORAGE_EXISTS = WINDOW_EXISTS && !!window.localStorage;
export const CREDENTIAL_API_EXISTS = WINDOW_EXISTS && !!window.PasswordCredential;

export const CHECKOUT_STEPS: CheckoutStep[] = [
  {
    name: 'address',
    link: '/checkout/address',
  },
  {
    name: 'shipping',
    link: '/checkout/shipping',
    onlyIfShippingRequired: true,
  },
  {
    name: 'payment',
    link: '/checkout/payment',
  },
];

export const PAYMENTS = {
  BONIFICO: {
    id: 'hypeclothinga.payments.bonifico',
    label: 'Bonifico',
  },
  NEXI: {
    id: 'hypeclothinga.payments.nexi',
    label: 'Nexi',
  },
  MASTERPASS: {
    id: 'hypeclothinga.payments.masterpass',
    label: 'Masterpass',
  },
  PAGANTIS: {
    id: 'hypeclothinga.payments.pagantis',
    label: 'Pagantis',
  },
  PAGANTIS4X: {
    id: 'hypeclothinga.payments.pagantis4x',
    label: 'Pagantis 4x',
  },
  CLEARPAY: {
    id: 'hypeclothinga.payments.clearpay',
    label: 'Clearpay',
  },
  SOISY: {
    id: 'hypeclothinga.payments.soisy',
    label: 'Soisy',
  },
  BITPAY: {
    id: 'hypeclothinga.payments.bitpay',
    label: 'BitPay',
  },
};

export const NEXI_LANGUAGE_ID = {
  it: 'ITA',
  en: 'ENG',
};

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  USD: '$',
  CAD: '$',
  AUD: '$',
  GBP: '£',
  JPY: '¥',
};
