/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import { ApolloOptions, initializeApollo } from 'lib/apollo';

function useApollo(initialState?: any, options?: ApolloOptions) {
  const store = useMemo(() => initializeApollo(initialState, options), [initialState, options]);
  return store;
}

export default useApollo;
