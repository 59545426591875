import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { LinkProps as NextLinkProps } from 'next/link';
import React from 'react';

import NextLink from './next-link';

const Link: React.FC<LinkProps & Omit<NextLinkProps, 'as'>> = ({
  children,
  href,
  locale,
  ...props
}) => {
  return (
    <NextLink href={href} locale={locale} prefetch={props.prefetch || false} passHref>
      <ChakraLink {...props}>{children}</ChakraLink>
    </NextLink>
  );
};

Link.displayName = 'AppLink';

export default Link;
