/* eslint-disable @typescript-eslint/no-explicit-any */
import round from 'lodash.round';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { applySnapshot, cast, flow, Instance, types as t } from 'mobx-state-tree';

import { initializeApollo } from 'lib/apollo';
import { ApolloClientManager, CompleteCheckoutInput } from 'lib/apollo-manager';
import {
  Checkout_availablePaymentGateways,
  Checkout_availableShippingMethods,
  Checkout_giftCards,
  Checkout_lines_variant_attributes,
  Checkout_lines_variant_pricing,
  Checkout_lines_variant_product,
} from 'lib/graphql/types/Checkout';
import { CompleteCheckout_checkoutComplete } from 'lib/graphql/types/CompleteCheckout';
import { AddressInput, LanguageCodeEnum } from 'lib/graphql/types/globalTypes';
import { Payment_total } from 'lib/graphql/types/Payment';
import { PaymentGateway } from 'lib/graphql/types/PaymentGateway';
import {
  AsyncReturnType,
  Checkout as CheckoutType,
  CheckoutAddress,
  CheckoutLine as CheckoutLineType,
  CheckoutLineDataItem,
  CheckoutPromoCodeDiscount,
  CheckoutShippingMethod,
  CreatePaymentInput,
  PriceValue,
  SummaryPrices,
} from 'types';

const Variant = t.model('Variant', {
  id: t.string,
  quantityAvailable: t.maybe(t.number),
  name: t.maybe(t.string),
  sku: t.maybe(t.string),
  pricing: t.maybe(t.frozen<Checkout_lines_variant_pricing>()),
  product: t.maybe(t.frozen<Checkout_lines_variant_product>()),
  isAvailable: t.maybe(t.boolean),
  attributes: t.maybe(t.array(t.frozen<Checkout_lines_variant_attributes>())),
});

const CheckoutLine = t
  .model('CheckoutLine', {
    id: t.maybeNull(t.string),
    quantity: t.number,
    variant: Variant,
    data: t.optional(t.array(t.frozen<CheckoutLineDataItem>()), []),
  })
  .views((self) => ({
    get totalPrice() {
      if (!self.variant.pricing?.price) {
        return null;
      }

      return {
        gross: {
          ...self.variant.pricing.price.gross,
          amount: self.variant.pricing.price.gross.amount * (self.quantity || 0),
        },
        net: {
          ...self.variant.pricing.price.net,
          amount: self.variant.pricing.price.net.amount * (self.quantity || 0),
        },
      };
    },
  }));

const Payment = t.model('Payment', {
  id: t.maybeNull(t.string),
  token: t.maybeNull(t.string),
  gateway: t.maybeNull(t.string),
  extraData: t.maybeNull(t.frozen<object>()),
  total: t.maybeNull(t.frozen<Payment_total>()),
});

const Checkout = t
  .model('Checkout', {
    isRedirecting: t.optional(t.boolean, false),
    id: t.maybeNull(t.string),
    token: t.maybeNull(t.string),
    email: t.maybeNull(t.string),
    lines: t.optional(t.array(CheckoutLine), []),
    shippingAddress: t.maybeNull(t.frozen<CheckoutAddress>()),
    billingAddress: t.maybeNull(t.frozen<CheckoutAddress>()),
    billingAsShipping: t.maybeNull(t.boolean),
    availablePaymentGateways: t.optional(t.array(t.frozen<PaymentGateway>()), []),
    availableShippingMethods: t.optional(
      t.array(t.frozen<Checkout_availableShippingMethods>()),
      [],
    ),
    payment: t.maybeNull(Payment),
    promoCodeDiscount: t.maybeNull(t.frozen<CheckoutPromoCodeDiscount>()),
    credit: t.maybeNull(t.frozen<PriceValue>()),
    canShowVoucherField: t.optional(t.boolean, false),
    giftCards: t.optional(t.array(t.frozen<Checkout_giftCards>()), []),
    shippingMethod: t.maybeNull(t.frozen<CheckoutType['shippingMethod']>()),
    selectedShippingAddressId: t.maybeNull(t.string),
    selectedBillingAddressId: t.maybeNull(t.string),
  })
  .views((self) => ({
    get totalPricing() {
      if (!self.lines.length) return {};

      const firstItemTotalPrice = self.lines[0].totalPrice;
      if (!firstItemTotalPrice) return {};

      const itemsNetPrice = self.lines.reduce(
        (accumulatorPrice, line) => accumulatorPrice + (line.totalPrice?.net.amount || 0),
        0,
      );
      const itemsGrossPrice = self.lines.reduce(
        (accumulatorPrice, line) => accumulatorPrice + (line.totalPrice?.gross?.amount || 0),
        0,
      );

      const subtotalPrice = {
        ...firstItemTotalPrice,
        gross: {
          ...firstItemTotalPrice.gross,
          amount: round(itemsGrossPrice, 2),
        },
        net: {
          ...firstItemTotalPrice.net,
          amount: round(itemsNetPrice, 2),
        },
      };

      return {
        subtotalPrice,
      };
    },

    get summaryPrices(): SummaryPrices {
      if (self.lines?.length) {
        const firstItemTotalPrice = self.lines[0].totalPrice;

        if (firstItemTotalPrice) {
          const shippingPrice = {
            ...self.shippingMethod?.price,
            amount: self.shippingMethod?.price?.amount || 0,
            currency: self.shippingMethod?.price?.currency || firstItemTotalPrice.gross.currency,
          };

          const itemsNetPrice = self.lines.reduce(
            (price, line) => price + (line.totalPrice?.net.amount || 0),
            0,
          );
          const itemsGrossPrice = self.lines.reduce(
            (price, line) => price + (line.totalPrice?.gross.amount || 0),
            0,
          );

          const subtotalPrice = {
            ...firstItemTotalPrice,
            gross: {
              ...firstItemTotalPrice.gross,
              amount: round(itemsGrossPrice, 2),
            },
            net: {
              ...firstItemTotalPrice.net,
              amount: round(itemsNetPrice, 2),
            },
          };

          const discount = {
            ...self.promoCodeDiscount?.discount,
            amount: self.promoCodeDiscount?.discount?.amount || 0,
            currency:
              self.promoCodeDiscount?.discount?.currency || firstItemTotalPrice.gross.currency,
          };

          if (self.giftCards?.length > 0) {
            let totalPriceLeft = itemsGrossPrice + shippingPrice.amount - discount.amount;

            self.giftCards.forEach((card) => {
              if (totalPriceLeft < card.currentBalance.amount) {
                discount.amount += totalPriceLeft;
              } else {
                discount.amount += card.currentBalance.amount;
              }
              totalPriceLeft = itemsGrossPrice + shippingPrice.amount - discount.amount;
            });
          }

          const credit = self.credit?.amount ?? 0;

          const totalPrice = {
            ...subtotalPrice,
            gross: {
              ...subtotalPrice.gross,
              amount: round(itemsGrossPrice + shippingPrice.amount - discount.amount - credit, 2),
            },
            net: {
              ...subtotalPrice.net,
              amount: round(itemsNetPrice + shippingPrice.amount - discount.amount - credit, 2),
            },
          };

          return {
            discount,
            shippingPrice,
            subtotalPrice,
            totalPrice,
          };
        }
      }

      return {};
    },

    get isEmpty(): boolean {
      return [
        'id',
        'token',
        'email',
        'lines',
        'shippingAddress',
        'billingAddress',
        'billingAsShipping',
        'availablePaymentGateways',
        'availableShippingMethods',
        'giftCards',
        'credit',
        'payment',
        'promoCodeDiscount',
        'shippingMethod',
        'selectedShippingAddressId',
        'selectedBillingAddressId',
      ].every((prop) => isEmpty(self[prop]));
    },

    get isShippingRequired() {
      return (
        self.lines?.length &&
        self.lines.some(({ variant }) => variant?.product?.productType.isShippingRequired)
      );
    },
  }))
  .actions((self) => {
    return {
      setRedirecting(redirecting: boolean) {
        self.isRedirecting = redirecting;
      },

      addLine(variantId: string, quantity: number) {
        const variantIndex = self.lines.findIndex((variant) => variant.variant.id === variantId);

        if (variantIndex !== -1) {
          self.lines[variantIndex].quantity += quantity;
        } else {
          self.lines.push({
            quantity,
            variant: {
              id: variantId,
            },
          });
        }
      },

      removeLine(variantId: string) {
        const variantIndex = self.lines.findIndex((variant) => variant.variant.id === variantId);

        if (variantIndex !== -1) {
          self.lines[variantIndex].quantity = 0;
        }
      },

      updateLine(variantId: string, quantity: number) {
        const variantIndex = self.lines.findIndex((variant) => variant.variant.id === variantId);

        if (variantIndex !== -1) {
          if (quantity > 0 && quantity <= self.lines[variantIndex].variant.quantityAvailable) {
            self.lines[variantIndex].quantity = quantity;
          }
        } else {
          self.lines.push({
            quantity,
            variant: {
              id: variantId,
            },
          });
        }
      },

      updateLines(lines: CheckoutLineType[]) {
        self.lines = cast(cloneDeep(lines));
      },

      updateGiftCards(giftCards: Checkout_giftCards[]) {
        self.giftCards = cast(cloneDeep(giftCards));
      },

      applyCheckout(checkout: CheckoutType) {
        const props = Object.getOwnPropertyNames(checkout);

        props.forEach((prop) => {
          if (checkout[prop] !== self[prop]) {
            self[prop] = cast(checkout[prop]);
          }
        });
      },

      setAvailablePaymentGateways(gateways: Checkout_availablePaymentGateways[]) {
        self.availablePaymentGateways = cast(cloneDeep(gateways));
      },

      setAvailableShippingMethods(methods: Checkout_availableShippingMethods[]) {
        self.availableShippingMethods = cast(cloneDeep(methods));
      },

      setPromoCode(promoCode: CheckoutPromoCodeDiscount) {
        self.promoCodeDiscount = cast(cloneDeep(promoCode));
      },

      setModelShippingMethod(shippingMethod: CheckoutShippingMethod) {
        self.shippingMethod = cast(cloneDeep(shippingMethod));
      },

      clearCheckout(redirecting = false, skipLines = false) {
        const snapshot: Partial<CheckoutModel> = {
          isRedirecting: redirecting,
        };

        if (skipLines) {
          snapshot.lines = cast(cloneDeep(self.lines));
        }

        applySnapshot(self, snapshot);
      },
    };
  })
  .actions((self) => {
    const apollo = initializeApollo();
    const apolloManager = new ApolloClientManager(apollo);

    return {
      setCheckout(checkout: CheckoutType) {
        self.updateLines(checkout.lines);
        self.setAvailablePaymentGateways(checkout.availablePaymentGateways);
        self.setAvailableShippingMethods(checkout.availableShippingMethods);
        self.setPromoCode(checkout.promoCodeDiscount);
        self.updateGiftCards(checkout.giftCards);
        self.setModelShippingMethod(checkout.shippingMethod);
        self.canShowVoucherField = checkout.canShowVoucherField;
        self.credit = checkout.credit;
      },

      incrementLine(variantId: string) {
        self.addLine(variantId, 1);
      },

      decrementLine(variantId: string) {
        const variantIndex = self.lines.findIndex((variant) => variant.variant.id === variantId);

        if (variantIndex !== -1 && self.lines[variantIndex].quantity > 1) {
          self.lines[variantIndex].quantity -= 1;
        }
      },

      createCheckout: flow(function* (
        email: string,
        shippingAddress?: AddressInput,
        billingAddress?: AddressInput,
        selectedShippingAddressId?: string,
        selectedBillingAddressId?: string,
        languageCode?: LanguageCodeEnum,
      ) {
        const alteredLines = self.lines?.map((item) => ({
          quantity: item.quantity,
          variantId: item?.variant.id,
        }));

        const { data, error } = (yield apolloManager.createCheckout(
          email,
          alteredLines,
          shippingAddress,
          billingAddress,
          languageCode,
        )) as AsyncReturnType<typeof apolloManager.createCheckout>;

        if (error) {
          return {
            error,
          };
        }

        if (data) {
          self.applyCheckout(data);
          self.selectedShippingAddressId = selectedShippingAddressId || data.shippingAddress?.id;
          self.selectedBillingAddressId = selectedBillingAddressId || data.billingAddress?.id;
        }

        return {
          data,
        };
      }),

      updateCheckoutShippingAddress: flow(function* (
        shippingAddress: AddressInput,
        email: string,
        selectedShippingAddressId?: string,
        selectedBillingAddressId?: string,
      ) {
        if (!self.id) {
          return {
            error: new Error('You need to set shipping address before setting shipping method.'),
          };
        }

        const { data, error } = (yield apolloManager.setShippingAddress(
          shippingAddress,
          email,
          self.id,
        )) as AsyncReturnType<typeof apolloManager['setShippingAddress']>;

        if (error) {
          return {
            error,
          };
        }

        if (data) {
          self.applyCheckout(data);
          self.selectedShippingAddressId = selectedShippingAddressId || data.shippingAddress?.id;
          self.selectedBillingAddressId = selectedBillingAddressId || data.billingAddress?.id;
        }

        return {
          data,
        };
      }),

      updateCheckoutBillingAddress: flow(function* (
        billingAddress: AddressInput,
        selectedShippingAddressId?: string,
        selectedBillingAddressId?: string,
      ) {
        if (!self.id) {
          return {
            error: new Error('You need to set shipping address before setting shipping method.'),
          };
        }

        const { data, error } = (yield apolloManager.setBillingAddress(
          billingAddress,
          self.id,
        )) as AsyncReturnType<typeof apolloManager['setBillingAddress']>;

        if (error) {
          return {
            error,
          };
        }

        if (data) {
          self.applyCheckout(data);
          self.selectedShippingAddressId = selectedShippingAddressId || data.shippingAddress?.id;
          self.selectedBillingAddressId = selectedBillingAddressId || data.billingAddress?.id;
        }

        return {
          data,
        };
      }),

      updateCheckoutBillingAddressWithEmail: flow(function* (
        billingAddress: AddressInput,
        email: string,
        selectedShippingAddressId?: string,
        selectedBillingAddressId?: string,
      ) {
        if (!self.id) {
          return {
            error: new Error('You need to set shipping address before setting shipping method.'),
          };
        }

        const { data, error } = (yield apolloManager.setBillingAddressWithEmail(
          billingAddress,
          email,
          self.id,
        )) as AsyncReturnType<typeof apolloManager['setBillingAddressWithEmail']>;

        if (error) {
          return {
            error,
          };
        }

        if (data) {
          self.applyCheckout(data);
          self.selectedShippingAddressId = selectedShippingAddressId || data.shippingAddress?.id;
          self.selectedBillingAddressId = selectedBillingAddressId || data.billingAddress?.id;
        }

        return {
          data,
        };
      }),

      setBillingAsShipping(billingAsShipping: boolean) {
        self.billingAsShipping = billingAsShipping;
      },

      setShippingMethod: flow(function* (shippingMethodId: string) {
        if (self.id) {
          const { data, error } = (yield apolloManager.setShippingMethod(
            shippingMethodId,
            self.id,
          )) as AsyncReturnType<typeof apolloManager.setShippingMethod>;

          if (error) {
            return {
              error,
            };
          }

          if (data) {
            self.promoCodeDiscount = data.promoCodeDiscount;
            self.shippingMethod = data.shippingMethod;
          }
          return {};
        }

        return {
          error: new Error('You need to set shipping address before setting shipping method.'),
        };
      }),

      createPayment: flow(function* (input: CreatePaymentInput) {
        const amount = self.summaryPrices?.totalPrice?.gross.amount;

        if (self.id && self.billingAddress && amount !== null && amount !== undefined) {
          const { data, error } = (yield apolloManager.createPayment({
            amount,
            billingAddress: self.billingAddress,
            checkoutId: self.id,
            gateway: input.gateway,
            returnUrl: input.returnUrl,
            token: input.token,
          })) as AsyncReturnType<typeof apolloManager['createPayment']>;

          if (error) {
            return {
              error,
            };
          }

          return {
            data,
          };
        }

        return {
          error: new Error('You need to set billing address before creating payment.'),
        };
      }),

      completeCheckout: flow(function* (
        input?: CompleteCheckoutInput,
        onSuccess?: (data?: CompleteCheckout_checkoutComplete, error?: any) => void,
      ) {
        if (self.id) {
          const { data, error } = (yield apolloManager.completeCheckout({
            checkoutId: self.id,
            paymentData: input.paymentData,
            redirectUrl: input.redirectUrl,
            storeSource: input.storeSource,
          })) as AsyncReturnType<typeof apolloManager['completeCheckout']>;

          if (error) {
            return {
              error,
            };
          }

          onSuccess?.(data, error);

          if (!onSuccess && !data?.confirmationNeeded) {
            self.clearCheckout();
          }

          return {
            data,
          };
        }

        return {
          error: new Error('You need to set shipping address before creating payment.'),
        };
      }),
    };
  })
  .actions((self) => {
    const apollo = initializeApollo();
    const apolloManager = new ApolloClientManager(apollo);

    return {
      addPromoCode: flow(function* (promoCode: string) {
        if (self.id) {
          const { data, error } = (yield apolloManager.addPromoCode(
            promoCode,
            self.id,
          )) as AsyncReturnType<typeof apolloManager['addPromoCode']>;

          if (error) {
            return {
              error,
            };
          }

          if (data) {
            self.setCheckout(data);
          }
          return {};
        }

        return {
          error: new Error('You need to set shipping address before modifying promo code.'),
        };
      }),

      removePromoCode: flow(function* (promoCode: string) {
        if (self.id) {
          const { data, error } = (yield apolloManager.removePromoCode(
            promoCode,
            self.id,
          )) as AsyncReturnType<typeof apolloManager['removePromoCode']>;

          if (error) {
            return {
              error,
            };
          }

          if (data) {
            self.setCheckout(data);
          }
          return {};
        }

        return {
          error: new Error('You need to set shipping address before modifying promo code.'),
        };
      }),

      addCredit: flow(function* () {
        if (self.id) {
          const { data, error } = (yield apolloManager.checkoutAddCredit(
            self.id,
          )) as AsyncReturnType<typeof apolloManager['checkoutAddCredit']>;

          if (error) {
            return {
              error,
            };
          }

          if (data) {
            self.setCheckout(data);
          }
          return {};
        }

        return {
          error: new Error('You need to set shipping address before adding credit.'),
        };
      }),

      removeCredit: flow(function* () {
        if (self.id) {
          const { data, error } = (yield apolloManager.checkoutRemoveCredit(
            self.id,
          )) as AsyncReturnType<typeof apolloManager['checkoutRemoveCredit']>;

          if (error) {
            return {
              error,
            };
          }

          if (data) {
            self.setCheckout(data);
          }
          return {};
        }

        return {
          error: new Error('You need to set shipping address before removing credit.'),
        };
      }),
    };
  });

export { Checkout };
export interface CheckoutModel extends Instance<typeof Checkout> {}
export interface CheckoutLineModel extends Instance<typeof CheckoutLine> {}
export interface PaymentModel extends Instance<typeof Payment> {}
