import { gql } from '@apollo/client';

export const baseCategoryFramgent = gql`
  fragment BaseCategory on Category {
    id
    slug
    name
    translation {
      slug
      name
    }
    backgroundImage(width: 1080, height: 440) {
      url
      alt
    }
  }
`;
