import { useEffect, useState } from 'react';

import {
  ProductDetails_product_variants,
  ProductDetails_product_variants_attributes_attribute,
  ProductDetails_product_variants_attributes_values,
} from 'lib/graphql/types/ProductDetails';

export interface ProductVariantsAttribute {
  attribute: ProductDetails_product_variants_attributes_attribute;
  values: ProductDetails_product_variants_attributes_values[];
}

export interface ProductVariantsAttributes {
  [key: string]: ProductVariantsAttribute;
}

export function useProductVariantsAttributes(
  productVariants: ProductDetails_product_variants[],
): ProductVariantsAttributes {
  const [
    productVariantsAttributes,
    setProductVariantsAttributes,
  ] = useState<ProductVariantsAttributes>({});

  useEffect(() => {
    const variantsAttributes: ProductVariantsAttributes = {};

    productVariants.forEach((productVariant) => {
      if (productVariant.quantityAvailable > 0) {
        productVariant.attributes.forEach((productVariantAttribute) => {
          const productVariantAttributeId = productVariantAttribute.attribute.id;
          const variantsAttributeExists = variantsAttributes.hasOwnProperty(
            productVariantAttributeId,
          );

          if (variantsAttributeExists) {
            const variantsAttributeValueExists = variantsAttributes[
              productVariantAttributeId
            ].values.includes(productVariantAttribute.values[0]);

            if (
              !variantsAttributeValueExists &&
              productVariantAttribute.values[0] !== undefined &&
              productVariantAttribute.values[0] !== null
            ) {
              variantsAttributes[productVariantAttributeId].values.push(
                productVariantAttribute.values[0],
              );
            }
          } else {
            variantsAttributes[productVariantAttributeId] = {
              attribute: productVariantAttribute.attribute,
              values: [productVariantAttribute.values[0]],
            };
          }
        });
      }
    });

    Object.keys(variantsAttributes).forEach((variantAttributeId) => {
      if (!Number.isNaN(variantsAttributes[variantAttributeId].values[0]?.name)) {
        variantsAttributes[variantAttributeId].values.sort(
          (a, b) => Number(a.name) - Number(b.name),
        );
      }
    });

    setProductVariantsAttributes(variantsAttributes);
  }, [productVariants]);

  return productVariantsAttributes;
}
