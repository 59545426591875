/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import theme from '@chakra-ui/theme';

const baseStyle = {
  field: {
    width: '100%',
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transition: 'all 0.2s',
    borderRadius: 'none',
  },
};

const size = {
  lg: {
    borderRadius: 'none',
  },

  md: {
    borderRadius: 'none',
  },

  sm: {
    borderRadius: 'none',
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
};

function variantProduct(props: Record<string, any>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const outline = (theme.components.Input.variants.outline as any)(props);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    ...outline,
    field: {
      ...outline.field,
      py: 3,
      height: 'auto',
    },
  };
}

export default {
  baseStyle,
  sizes,
  variants: {
    product: variantProduct,
    outline: {
      field: {
        borderColor: 'black',
        _hover: {
          borderColor: 'black',
        },
        _focus: {
          borderColor: 'black',
        },
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'primary',
  },
};
