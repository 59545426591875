import { useToast as useOriginalToast, UseToastOptions } from '@chakra-ui/react';
import { GraphQLError as QueryError } from 'graphql';
import useTranslation from 'next-translate/useTranslation';

import { GraphQLError } from 'types';

export function useToast(options?: UseToastOptions) {
  const toast = useOriginalToast({
    isClosable: true,
    position: 'bottom',
    ...options,
  });

  return (options?: UseToastOptions) => {
    toast.closeAll();

    return toast(options);
  };
}

export function useSuccessToast(options?: UseToastOptions) {
  const toast = useOriginalToast({
    isClosable: true,
    position: 'bottom',
    status: 'success',
    ...options,
  });

  return (options?: UseToastOptions) => {
    toast.closeAll();

    return toast(options);
  };
}

export function useErrorToast(options?: UseToastOptions) {
  const toast = useOriginalToast({
    isClosable: true,
    position: 'bottom',
    status: 'error',
    ...options,
  });

  return (options?: UseToastOptions) => {
    toast.closeAll();

    return toast(options);
  };
}

export function useGraphqlErrorToast() {
  const { t } = useTranslation('common');
  const toast = useErrorToast();

  const showErrorToast = (errors: GraphQLError[] | readonly QueryError[]) => {
    if (!errors.length) return;

    const errorMessage = (errors as (GraphQLError | QueryError)[])
      .map((error) =>
        error instanceof QueryError
          ? error.message
          : t(`errors.${error.__typename}.${error.field ? `${error.field}.` : ''}${error.code}`),
      )
      .join('\n');

    toast({
      title: t('errors.error'),
      description: errorMessage,
    });
  };

  return showErrorToast;
}
