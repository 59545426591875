/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
// import { createHttpLink } from '@apollo/client/link/http';
import { relayStylePagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import merge from 'deepmerge';
import { GetStaticPropsResult } from 'next';

interface ResponseError extends ErrorResponse {
  networkError?: Error & {
    statusCode?: number;
    bodyText?: string;
  };
}

export interface ApolloOptions {
  tokenExpirationCallback?(): void;
  links?: ApolloLink[];
  token?: string;
  locale?: string;
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const httpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const ssrAuthLink = (token: string) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `JWT ${token}`,
      },
    };
  });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
          null,
          2,
        )}, Path: ${path}`,
      ),
    );

  if (networkError) {
    console.error(networkError);
    console.log(`[Network error]: ${networkError}`);
  }
});

const invalidTokenLinkWithTokenHandler = (tokenExpirationCallback: () => void): ApolloLink => {
  const link = onError((error: ResponseError) => {
    const isTokenExpired = error.graphQLErrors?.some(
      (gqlError) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        gqlError.extensions?.exception?.code === 'JSONWebTokenExpired',
    );
    if (isTokenExpired || (error.networkError && error.networkError.statusCode === 401)) {
      tokenExpirationCallback();
    }
  });
  return link;
};

const localeLink = (locale: string) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'Accept-Language': locale,
      },
    };
  });

export function createApolloClient({
  tokenExpirationCallback,
  links = [],
  token,
  locale,
}: ApolloOptions) {
  const ssrMode = typeof window === 'undefined';

  return new ApolloClient({
    ssrMode,
    link: ApolloLink.from(
      [
        !ssrMode && authLink,
        ssrMode && token && ssrAuthLink(token),
        locale && localeLink(locale),
        errorLink,
        tokenExpirationCallback && invalidTokenLinkWithTokenHandler(tokenExpirationCallback),
        ...links,
        httpLink,
      ].filter(Boolean),
    ),
    cache: new InMemoryCache({
      addTypename: true,
      // dataIdFromObject: (obj) => {
      //   if (obj.__typename === 'Shop') {
      //     return 'shop';
      //   }
      //   return defaultDataIdFromObject(obj);
      // },
      typePolicies: {
        Query: {
          fields: {
            products: relayStylePagination(['filter', 'sortBy']),
          },
        },
        User: {
          fields: {
            orders: relayStylePagination(['filter', 'sortBy']),
          },
        },
      },
    }),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });
}

export function initializeApollo(initialState = null, options?: ApolloOptions) {
  const _apolloClient = apolloClient ?? createApolloClient(options || {});

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);
    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState<P extends object = object>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: GetStaticPropsResult<P>,
) {
  if ('props' in pageProps && pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}
