
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { NormalizedCacheObject } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { AppProps as NextAppProps } from 'next/app';
import { Router } from 'next/router';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import { FC } from 'react';

import CookieConsent from 'components/layout/cookie-consent';
import { SaleorProvider } from 'context/saleor';
import { ShopProvider } from 'context/shop';
import { UIProvider } from 'context/ui';
import * as gtag from 'lib/gtag';
import { init } from 'lib/sentry';
import theme from 'theme';
import { PageComponent } from 'types';

import 'nprogress/css/nprogress.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'styles/swiper-navigation.css';
import 'styles/swiper-pagination.css';
import 'styles/soisy-afterpay-widget.css';

init();

NProgress.configure({
  showSpinner: false,
});

Router.events.on('routeChangeStart', (url) => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', (url) => {
  gtag.pageview(url);
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

interface AppProps extends NextAppProps {
  initialApolloState: NormalizedCacheObject;
  Component: NextAppProps['Component'] & {
    getLayout: PageComponent['getLayout'];
  };
  err?: Error;
}

const App: FC<AppProps> = ({ Component, pageProps, initialApolloState, err, router }) => {
  const getLayout: PageComponent['getLayout'] = Component.getLayout || ((page) => page);

  return (
    <ChakraProvider resetCSS theme={theme}>
      <SaleorProvider initialApolloState={pageProps.initialApolloState || initialApolloState}>
        <ShopProvider
          localePaths={pageProps.localePaths || []}
          conversionRates={pageProps.conversionRates}
        >
          <UIProvider>
            <DefaultSeo title="Hype Clothinga" />
            {getLayout(<Component {...pageProps} err={err} />, router, pageProps)}
            <CookieConsent />
          </UIProvider>
        </ShopProvider>
      </SaleorProvider>
    </ChakraProvider>
  );
};

const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  