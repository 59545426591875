import { useDisclosure } from '@chakra-ui/react';
import { createContext, FC } from 'react';

export interface UIState {
  isCartOpen: boolean;
  openCart(): void;
  closeCart(): void;
  toggleCart(): void;
  isMenuOpen: boolean;
  openMenu(): void;
  closeMenu(): void;
  toggleMenu(): void;
  settings: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: unknown) => unknown;
    getDisclosureProps: (props?: unknown) => unknown;
  };
}

export const UIContext = createContext<UIState | undefined>(undefined);

export const UIProvider: FC = ({ children }) => {
  const {
    isOpen: isCartOpen,
    onOpen: openCart,
    onClose: closeCart,
    onToggle: toggleCart,
  } = useDisclosure({
    onOpen() {
      if (isMenuOpen) {
        closeMenu();
      }
    },
  });
  const {
    isOpen: isMenuOpen,
    onOpen: openMenu,
    onClose: closeMenu,
    onToggle: toggleMenu,
  } = useDisclosure();
  const settings = useDisclosure();

  return (
    <UIContext.Provider
      value={{
        isCartOpen,
        openCart,
        closeCart,
        toggleCart,
        isMenuOpen,
        openMenu,
        closeMenu,
        toggleMenu,
        settings,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
