const button = {
  baseStyle: {
    borderRadius: 'none',
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 'sm',
    _hover: {
      boxShadow: 'none',
    },
    _active: {
      boxShadow: 'none',
    },
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    solid: {
      bg: 'primary',
      color: 'white',
      fontSize: 'sm',
      textTransform: 'uppercase',
      letterSpacing: 'superWide',
      _hover: {
        bg: 'black',
      },
      _active: {
        bg: 'black',
      },
    },
    reversed: {
      bg: 'black',
      color: 'white',
      fontSize: 'sm',
      textTransform: 'uppercase',
      letterSpacing: 'superWide',
      _hover: {
        bg: 'primary',
      },
      _active: {
        bg: 'primary',
      },
    },
    outline: {
      border: '1px solid',
      borderColor: 'black',
      color: 'black',
      textTransform: 'uppercase',
      fontWeight: 'semibold',
      letterSpacing: 'widest',
      borderRadius: 'none',
      _hover: {
        bg: 'primary',
        borderColor: 'primary',
        color: 'white',
      },
      _active: {
        bg: 'primary',
        borderColor: 'primary',
        color: 'white',
      },
    },
    link: {
      padding: 0,
      height: 'auto',
      color: 'black',
      textDecoration: 'none',
      _hover: {
        color: 'darkGray.500',
        textDecoration: 'none',
      },
      _active: {
        color: 'darkGray.500',
        textDecoration: 'none',
      },
      _focus: {
        color: 'darkGray.500',
        textDecoration: 'none',
      },
    },
    iconOutline: {
      border: 'none',
      color: 'black',
      borderRadius: 'none',
      fontWeight: 'semibold',
      _hover: {
        color: 'primary',
      },
      _active: {
        color: 'primary',
      },
      _focus: {
        borderRadius: 'none',
      },
    },
    addToCart: {
      bg: 'black',
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: 'wide',
      fontFamily: 'heading',
      _hover: {
        bg: 'black',
        _disabled: {
          opacity: 0.8,
          bg: 'black',
          cursor: 'auto',
        },
      },
      _disabled: {
        cursor: 'auto',
        opacity: 0.8,
      },
      _active: {
        bg: 'black',
      },
    },
    filtersLink: {
      w: 'full',
      padding: 0,
      height: 'auto',
      color: 'black',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'flex-start',
      py: 3,
      fontWeight: 'semibold',
      _hover: {
        color: 'primary',
        textDecoration: 'none',
        boxShadow: 'none',
      },
      _active: {
        color: 'primary',
        textDecoration: 'none',
        boxShadow: 'none',
      },
      _focus: {
        color: 'primary',
        textDecoration: 'none',
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    product: {
      py: 3,
    },
  },
};

export default button;
