import Link, { LinkProps } from 'next/link';
import { FC } from 'react';

const NextLink: FC<LinkProps> = ({ children, ...rest }) => {
  return (
    <Link prefetch={false} passHref {...rest}>
      {children}
    </Link>
  );
};

export default NextLink;
