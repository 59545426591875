import { gql } from '@apollo/client';

import { productPricingFragment } from '../queries/products';
import { pageInfo } from './pageInfo';
import { baseProduct } from './products';

export const wishlistFragment = gql`
  ${baseProduct}
  ${productPricingFragment}
  ${pageInfo}
  fragment Wishlist on Wishlist {
    id
    products(first: $first, after: $after, last: $last, before: $before) {
      edges {
        node {
          ...BaseProduct
          ...ProductPricingField
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;
