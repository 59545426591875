import theme from '@chakra-ui/theme';
import merge from 'deepmerge';

export default merge(theme.components.Heading, {
  baseStyle: {
    fontWeight: 'semibold',
    fontFamily: 'inherit',
  },
  variants: {
    footerNav: {
      fontSize: '2xl',
      position: 'relative',
      mb: 3,
      pb: 4,
      _before: {
        content: "''",
        position: 'absolute',
        left: 0,
        bottom: 0,
        w: 16,
        borderBottom: '1px',
      },
    },
  },
});
