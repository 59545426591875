import { _after, _afterHover } from 'theme/underline';

const navVariant = {
  position: 'relative',
  padding: 2,
  textDecoration: 'none',
  fontWeight: 'semibold',
  fontSize: 'sm',
  color: 'black',
  letterSpacing: 'wider',
  _hover: {
    color: 'black',
    textDecoration: 'none',
    _after: {
      left: 0,
      width: '100%',
    },
  },
  _active: {
    color: 'black',
  },
  _focus: {
    color: 'black',
  },
  _after: {
    content: '""',
    borderBottom: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    position: 'absolute',
    right: 0,
    width: 0,
    bottom: 0,
    transition: 'width .4s linear 0s',
  },
};

const mobileNavVariation = {
  width: 'full',
  p: 4,
  display: 'inline-block',
  fontWeight: 'normal',
};

export default {
  baseStyle: {
    color: 'black',
    textDecoration: 'none',
    _hover: {
      color: 'darkGrayText',
      textDecoration: 'none',
      boxShadow: 'none',
    },
    _active: {
      color: 'darkGrayText',
      textDecoration: 'none',
      boxShadow: 'none',
    },
    _focus: {
      color: 'darkGrayText',
      textDecoration: 'none',
      boxShadow: 'none',
    },
  },
  variants: {
    nav: navVariant,
    navActive: {
      ...navVariant,
      color: 'black',
      _after: {
        ...navVariant._after,
        left: 0,
        width: 'full',
      },
    },
    mobileNav: mobileNavVariation,
    mobileNavActive: {
      ...mobileNavVariation,
    },
    button: {
      bg: 'button',
      color: 'white',
      px: {
        base: 5,
        md: 6,
      },
      py: {
        base: 2,
        md: 3,
      },
      _hover: {
        color: 'white',
        bg: 'black',
      },
      _active: {
        color: 'white',
        bg: 'black',
      },
      _focus: {
        color: 'white',
        bg: 'black',
      },
    },
    buttonDark: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'black',
      color: 'black',
      display: 'inline-block',
      px: {
        base: 5,
        md: 6,
        lg: 8,
      },
      py: {
        base: 2,
        md: 3,
        lg: 4,
      },
      mt: 10,
      _hover: {
        bg: 'black',
        color: 'white',
      },
      _focus: {
        bg: 'black',
        color: 'white',
      },
    },
    underline: {
      color: 'black',
      position: 'relative',
      pb: 2,
      _hover: {
        color: 'black',
        _after: _afterHover,
      },
      _after,
    },
  },
};
