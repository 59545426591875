import { gql } from '@apollo/client';

import { metadataErrorFragment } from '../fragments/errors';

export const updateMetadataMutation = gql`
  ${metadataErrorFragment}
  mutation UpdateMetadata($id: ID!, $input: [MetadataInput!]!) {
    updateMetadata(id: $id, input: $input) {
      item {
        metadata {
          key
          value
        }
      }
      metadataErrors {
        ...MetadataError
      }
    }
  }
`;
