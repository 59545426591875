import {
  DocumentNode,
  MutationOptions,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback, useState } from 'react';

import { GraphQLError as QueryError } from 'types';
import { useApollo } from './';

interface UseMutationOptions<TData = unknown> {
  onSuccess?(data: TData): void;
  onError?(error: readonly GraphQLError[] | QueryError[]): void;
  extractError?(data: TData): QueryError[] | null;
}

interface MutationResult<TData = unknown> {
  data?: TData;
  dataError?: readonly GraphQLError[] | QueryError[];
}

function useMutation<TData = unknown, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: UseMutationOptions<TData>,
) {
  const apollo = useApollo();
  const [result, setResult] = useState<MutationResult<TData>>();
  const [loading, setLoading] = useState(false);

  const mutate = useCallback(
    async (
      mutationOptions?: Omit<MutationOptions<TData, TVariables>, 'mutation'> &
        Omit<UseMutationOptions<TData>, 'extractError'>,
    ) => {
      setLoading(true);
      const { data, errors } = await apollo.mutate<TData, TVariables>({
        mutation,
        ...mutationOptions,
      });

      const dataError = errors?.length ? errors : options?.extractError?.(data) || null;
      if (dataError?.length) {
        options?.onError?.(dataError);
        mutationOptions?.onError?.(dataError);
      } else {
        options?.onSuccess?.(data);
        mutationOptions?.onSuccess?.(data);
      }

      setResult({
        data,
        dataError,
      });
      setLoading(false);

      return {
        data,
        dataError,
      };
    },
    [apollo, mutation, options],
  );

  return [
    mutate,
    {
      ...result,
      loading,
    },
  ] as const;
}

export { useMutation };
