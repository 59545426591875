import { gql } from '@apollo/client';

import { baseCategoryFramgent } from '../fragments/category';
import { checkoutPriceFragment } from '../fragments/checkout';
import { pageInfo } from '../fragments/pageInfo';
import {
  attributeFragment,
  baseProduct,
  categoryFragment,
  productVariantFragment,
  translatedProductFragment,
} from '../fragments/products';

export const productPricingFragment = gql`
  ${checkoutPriceFragment}
  fragment ProductPricingField on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...Price
        }
      }
      priceRange {
        start {
          ...Price
        }
      }
    }
  }
`;

export const productList = gql`
  ${baseProduct}
  ${productPricingFragment}
  ${pageInfo}
  query ProductList(
    $after: String
    $first: Int!
    $sortBy: ProductOrder
    $filter: ProductFilterInput
  ) {
    products(after: $after, first: $first, sortBy: $sortBy, filter: $filter) {
      edges {
        node {
          ...BaseProduct
          ...ProductPricingField
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

export const productSearchQuery = gql`
  ${baseProduct}
  ${productPricingFragment}
  ${pageInfo}
  query ProductSearch($after: String, $first: Int!, $search: String!) {
    products(
      after: $after
      first: $first
      filter: { search: $search }
      sortBy: { field: DATE, direction: DESC }
    ) {
      edges {
        node {
          ...BaseProduct
          ...ProductPricingField
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

export const productSlugList = gql`
  ${pageInfo}
  query ProductSlugList($after: String, $first: Int!, $filter: ProductFilterInput) {
    products(after: $after, first: $first, filter: $filter) {
      edges {
        node {
          id
          slug
          en: translation(languageCode: EN) {
            slug
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

export const baseProductDetails = gql`
  ${baseProduct}
  ${translatedProductFragment}
  query BaseProductDetails($slug: String!) {
    product(slug: $slug) {
      ...BaseProduct
      descriptionJson
      sku
      productType {
        id
        hasVariants
      }
      category {
        id
        name
        slug
        translation {
          name
          slug
        }
      }
      categories {
        id
      }
      translations {
        slug
        language {
          code
        }
      }
      socialThumbnail: thumbnail(width: 1200, height: 630) {
        url
        alt
      }
      images {
        id
        url(size: 1080)
        alt
      }
      seoDescription
      seoTitle
      isAvailable
      isAvailableForPurchase
      translation {
        ...TranslatedProduct
      }
      metadata {
        key
        value
      }
    }
  }
`;

export const relatedProductsQuery = gql`
  ${baseProduct}
  ${productPricingFragment}
  query RelatedProducts($categories: [ID!]!, $exclude: [ID!]) {
    products(
      first: 8
      filter: { categories: $categories, excludeIds: $exclude }
      sortBy: { field: CREATED_AT, direction: DESC }
    ) {
      edges {
        node {
          ...BaseProduct
          ...ProductPricingField
        }
      }
    }
  }
`;

export const productDetails = gql`
  ${productVariantFragment}
  ${productPricingFragment}
  query ProductDetails($slug: String!, $countryCode: CountryCode) {
    product(slug: $slug) {
      ...ProductPricingField
      isAvailable
      variants {
        ...ProductVariantFields
      }
    }
  }
`;

export const variantsProducts = gql`
  query VariantsProducts($ids: [ID]) {
    productVariants(ids: $ids, first: 100) {
      edges {
        node {
          id
          product {
            id
            productType {
              isShippingRequired
            }
          }
        }
      }
    }
  }
`;

export const homeCollectionsQuery = gql`
  ${baseProduct}
  ${productPricingFragment}
  ${baseCategoryFramgent}
  query HomeCollections {
    newProducts: products(
      first: 12
      filter: { isPublished: true }
      sortBy: { field: CREATED_AT, direction: DESC }
    ) {
      edges {
        node {
          ...BaseProduct
          ...ProductPricingField
        }
      }
    }
    categoryBox1: category(id: "Q2F0ZWdvcnk6OTI=") {
      ...BaseCategory
    }
    categoryBox2: category(id: "Q2F0ZWdvcnk6MTIy") {
      ...BaseCategory
    }
    categorySection2: category(id: "Q2F0ZWdvcnk6MTIy") {
      slug
      translation {
        slug
      }
    }
    productsSection2: products(
      first: 8
      filter: {
        isPublished: true
        categories: ["Q2F0ZWdvcnk6MTIz", "Q2F0ZWdvcnk6MTI0"]
        minimalPrice: { lte: 250 }
      }
      sortBy: { field: CREATED_AT, direction: DESC }
    ) {
      edges {
        node {
          ...BaseProduct
          ...ProductPricingField
        }
      }
    }
    categoryBox3: category(id: "Q2F0ZWdvcnk6Nw==") {
      ...BaseCategory
    }
    categoryBox4: category(id: "Q2F0ZWdvcnk6Njk=") {
      ...BaseCategory
    }
  }
`;

export const sellFormQuery = gql`
  fragment AttributeTranslationFragment on Attribute {
    id
    name
    translation {
      id
      name
    }
    values {
      id
      name
      translation {
        id
        name
      }
    }
  }
  query SellForm($brandId: ID!) {
    productTypes(first: 50) {
      edges {
        node {
          id
          name
          slug
          translation {
            name
          }
          variantAttributes {
            ...AttributeTranslationFragment
          }
        }
      }
    }
    brand: category(id: $brandId) {
      children(first: 100) {
        edges {
          node {
            id
            name
            translation {
              name
            }
          }
        }
      }
    }
  }
`;

export const attributesQuery = gql`
  ${attributeFragment}
  query Attributes($filter: AttributeFilterInput) {
    attributes(first: 50, filter: $filter, sortBy: { field: SLUG, direction: ASC }) {
      edges {
        node {
          ...Attribute
        }
      }
    }
  }
`;

export const attributeDetailsQuery = gql`
  ${attributeFragment}
  query AttributeDetails($id: ID!) {
    attribute(id: $id) {
      ...Attribute
    }
  }
`;

export const filtersQuery = gql`
  ${categoryFragment}
  ${attributeFragment}
  query Filters(
    $categoryFilters: CategoryFilterInput
    $level: Int
    $attributeFilters: AttributeFilterInput
    $min: Int = 0
    $max: Int = 10000
    $inCategories: [ID]
  ) {
    categories(
      first: 100
      filter: $categoryFilters
      level: $level
      sortBy: { field: NAME, direction: ASC }
    ) {
      edges {
        node {
          ...Category
          children(first: 100) {
            edges {
              node {
                ...Category
              }
            }
          }
        }
      }
    }
    attributes(first: 100, filter: $attributeFilters, sortBy: { field: NAME, direction: ASC }) {
      edges {
        node {
          ...Attribute
        }
      }
    }
    priceRange(min: $min, max: $max, inCategories: $inCategories) {
      start {
        amount
        currency
      }
      stop {
        amount
        currency
      }
    }
  }
`;
