export const _after = {
  content: '""',
  borderBottom: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: 'black',
  position: 'absolute',
  right: 0,
  width: 0,
  bottom: 0,
  transition: 'width .4s linear 0s',
};

export const _afterHover = {
  left: 0,
  width: '100%',
};
