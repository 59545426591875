import { gql } from '@apollo/client';

export const checkoutErrorFragment = gql`
  fragment CheckoutError on CheckoutError {
    code
    field
    message
  }
`;

export const paymentErrorFragment = gql`
  fragment PaymentError on PaymentError {
    code
    field
    message
  }
`;

export const accountErrorFragment = gql`
  fragment AccountError on AccountError {
    code
    field
    message
  }
`;

export const productErrorFragment = gql`
  fragment ProductError on ProductError {
    code
    field
    message
  }
`;

export const metadataErrorFragment = gql`
  fragment MetadataError on MetadataError {
    code
    field
    message
  }
`;

export const translationErrorFragment = gql`
  fragment TranslationError on TranslationError {
    code
    field
    message
  }
`;

export const wishlistErrorFragment = gql`
  fragment WishlistError on WishlistError {
    code
    field
    message
  }
`;
