const baseStyleContainer = {
  borderColor: 'black',
  borderLeftWidth: '1px',
  borderRightWidth: '1px',
};

const baseStyleButton = {
  py: 4,
  px: 6,
};

export default {
  baseStyle: {
    container: baseStyleContainer,
    button: baseStyleButton,
  },
};
