import { gql } from '@apollo/client';

import { checkoutAddressFragment } from './checkout';

export const orderPriceFragment = gql`
  fragment OrderPrice on TaxedMoney {
    gross {
      amount
      currency
    }
    net {
      amount
      currency
    }
  }
`;

export const orderDetailFragment = gql`
  ${orderPriceFragment}
  ${checkoutAddressFragment}
  fragment OrderDetail on Order {
    id
    userEmail
    number
    paymentStatus
    paymentStatusDisplay
    status
    statusDisplay
    shippingAddress {
      ...Address
    }
    billingAddress {
      ...Address
    }
    lines {
      id
      variantName
      translatedVariantName
      productName
      translatedProductName
      thumbnail {
        alt
        url
      }
      quantity
      unitPrice {
        currency
        ...OrderPrice
      }
      totalPrice {
        currency
        ...OrderPrice
      }
    }
    subtotal {
      ...OrderPrice
    }
    total {
      ...OrderPrice
    }
    discount {
      amount
      currency
    }
    credit {
      amount
      currency
    }
    shippingPrice {
      ...OrderPrice
    }
  }
`;
