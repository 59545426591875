const baseStyle = {
  width: '100%',
  outline: 0,
  position: 'relative',
  appearance: 'none',
  transition: 'all 0.2s',
  borderRadius: 'none',
};

export default {
  baseStyle,
  variants: {
    outline: {
      borderRadius: 'none',
      borderColor: 'black',
      _hover: {
        borderColor: 'black',
      },
      _focus: {
        borderColor: 'black',
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'primary',
  },
};
