import { gql } from '@apollo/client';

import { userFragment } from '../fragments/auth';
import { checkoutAddressFragment } from '../fragments/checkout';
import { accountErrorFragment } from '../fragments/errors';

export const confirmAccountMutation = gql`
  ${userFragment}
  ${accountErrorFragment}
  mutation ConfirmAccount($email: String!, $token: String!) {
    confirmAccount(email: $email, token: $token) {
      accountErrors {
        ...AccountError
      }
      user {
        ...User
      }
    }
  }
`;

export const changeUserPasswordMutation = gql`
  ${accountErrorFragment}
  mutation PasswordChange($newPassword: String!, $oldPassword: String!) {
    passwordChange(newPassword: $newPassword, oldPassword: $oldPassword) {
      errors: accountErrors {
        ...AccountError
      }
    }
  }
`;

export const accountUpdateMutation = gql`
  ${userFragment}
  ${accountErrorFragment}
  mutation AccountUpdate($input: AccountInput!) {
    accountUpdate(input: $input) {
      errors: accountErrors {
        ...AccountError
      }
      user {
        ...User
      }
    }
  }
`;

export const accountAddressCreateMutation = gql`
  ${accountErrorFragment}
  ${checkoutAddressFragment}
  mutation AccountAddressCreate($input: AddressInput!, $type: AddressTypeEnum) {
    accountAddressCreate(input: $input, type: $type) {
      address {
        ...Address
      }
      user {
        addresses {
          ...Address
        }
      }
      accountErrors {
        ...AccountError
      }
    }
  }
`;

export const accountAddressUpdateMutation = gql`
  ${accountErrorFragment}
  ${checkoutAddressFragment}
  mutation AccountAddressUpdate($id: ID!, $input: AddressInput!) {
    accountAddressUpdate(id: $id, input: $input) {
      address {
        ...Address
      }
      user {
        addresses {
          ...Address
        }
      }
      accountErrors {
        ...AccountError
      }
    }
  }
`;

export const accountAddressDeleteMutation = gql`
  ${accountErrorFragment}
  ${checkoutAddressFragment}
  mutation AccountAddressDelete($id: ID!) {
    accountAddressDelete(id: $id) {
      address {
        ...Address
      }
      accountErrors {
        ...AccountError
      }
    }
  }
`;

export const accountSetDefaultAddressMutation = gql`
  ${accountErrorFragment}
  ${checkoutAddressFragment}
  mutation AccountSetDefaultAddress($id: ID!, $type: AddressTypeEnum!) {
    accountSetDefaultAddress(id: $id, type: $type) {
      accountErrors {
        ...AccountError
      }
      user {
        addresses {
          ...Address
        }
      }
    }
  }
`;

export const setPasswordMutation = gql`
  ${userFragment}
  ${accountErrorFragment}
  mutation SetPassword($token: String!, $email: String!, $password: String!) {
    setPassword(token: $token, email: $email, password: $password) {
      token
      csrfToken
      user {
        ...User
      }
      accountErrors {
        ...AccountError
      }
    }
  }
`;
