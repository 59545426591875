import * as z from 'zod';

import { CountryCode } from './graphql/types/globalTypes';

export const AddressValidator = z.object({
  firstName: z.string(),
  lastName: z.string(),
  streetAddress1: z.string(),
  streetAddress2: z.string().optional(),
  city: z.string(),
  postalCode: z.string(),
  country: z.nativeEnum(CountryCode),
  countryArea: z.string(),
  phone: z.string(),
});
