import { useRouter } from 'next/router';

import { getLocalizedLink, pageLinks } from 'lib/links';

export function useLocalizedLink() {
  const { locale } = useRouter();

  return function localizeLink(page: keyof typeof pageLinks) {
    return getLocalizedLink(page, locale);
  };
}
