/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { FetchResult } from '@apollo/client';
import omit from 'lodash/omit';
import {
  applySnapshot,
  cast,
  flow,
  getParent,
  getSnapshot,
  Instance,
  types as t,
} from 'mobx-state-tree';
import { JsonObject } from 'type-fest';

import { initializeApollo } from 'lib/apollo';
import { ApolloClientManager } from 'lib/apollo-manager';
import { CREDENTIAL_API_EXISTS, LOCAL_STORAGE_EXISTS } from 'lib/consts';
import { updateMetadataMutation } from 'lib/graphql/mutations/meta';
import { accountAddressCreateMutation } from 'lib/graphql/mutations/user';
import {
  AccountAddressCreate,
  AccountAddressCreateVariables,
} from 'lib/graphql/types/AccountAddressCreate';
import { Address } from 'lib/graphql/types/Address';
import { AddressTypeEnum } from 'lib/graphql/types/globalTypes';
import { UpdateMetadata, UpdateMetadataVariables } from 'lib/graphql/types/UpdateMetadata';
import { User as UserType } from 'lib/graphql/types/User';
import { parseMetadata } from 'lib/utils/common';
import { AsyncReturnType, PriceValue, WPOrder } from 'types';
import { CartModel } from './Cart';
import { CheckoutModel } from './Checkout';
import { RootModel } from './Root';

const User = t.model({
  id: t.identifier,
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  isStaff: t.boolean,
  credit: t.maybeNull(t.frozen<PriceValue>()),
  defaultShippingAddress: t.maybeNull(t.frozen<Address>()),
  defaultBillingAddress: t.maybeNull(t.frozen<Address>()),
  addresses: t.maybeNull(t.array(t.frozen<Address>())),
  metadata: t.maybeNull(t.frozen<JsonObject & { orders?: WPOrder[] }>()),
});

export const Wishlist = t
  .model({
    id: t.maybeNull(t.string),
    productIds: t.optional(t.array(t.string), []),
  })
  .views((self) => ({
    containsProduct(productId: string) {
      return self.productIds.includes(productId);
    },

    get productCount() {
      return self.productIds.length;
    },
  }))
  .actions((self) => ({
    setId(id: string) {
      self.id = id;
    },

    setProductIds(ids: string[]) {
      self.productIds = cast(ids);
    },

    setWishlist(wishlist: { id: string; productIds: string[] }) {
      self.id = wishlist.id;
      self.productIds = cast(wishlist.productIds);
    },

    addProduct(id: string) {
      if (!self.productIds.includes(id)) {
        self.productIds.push(id);
      }
    },

    removeProduct(id: string) {
      if (self.productIds.includes(id)) {
        self.productIds.remove(id);
      }
    },
  }));

export const Auth = t
  .model('Auth', {
    user: t.maybeNull(User),
    wishlist: t.optional(Wishlist, {}),
    token: t.maybeNull(t.string),
    csrfToken: t.maybeNull(t.string),
    loading: t.optional(t.boolean, false),
    tokenRefreshing: t.optional(t.boolean, false),
    tokenVerifying: t.optional(t.boolean, false),
    loadingUserInfo: t.optional(t.boolean, false),
  })
  .views((self) => ({
    get isAuthenticated() {
      return !!self.user?.id;
    },

    get canCheckout() {
      return !self.loadingUserInfo && !self.tokenRefreshing && !self.tokenVerifying;
    },
  }))
  .actions((self) => {
    const apollo = initializeApollo();
    const apolloManager = new ApolloClientManager(apollo);
    const checkout: CheckoutModel = (getParent(self) as RootModel).checkout;

    return {
      getAddressById(id: string) {
        return self.user?.addresses.find((address) => address.id === id);
      },

      setUserInfo: flow(function* () {
        self.loadingUserInfo = true;

        const {
          data: userData,
          error: userError,
        } = (yield apolloManager.getUser()) as AsyncReturnType<typeof apolloManager['getUser']>;

        if (userError) {
          self.loadingUserInfo = false;
          return {
            error: userError,
          };
        }

        if (!userData) {
          self.user = null;
          return;
        }

        self.user = cast(omit(userData, 'checkout', 'metadata'));
        self.user.metadata = parseMetadata(userData?.metadata);

        if (self.wishlist?.productIds?.length > 0) {
          const { data } = (yield apolloManager.wishlistSyncProducts(
            self.wishlist.productIds,
          )) as AsyncReturnType<typeof apolloManager['wishlistSyncProducts']>;

          if (data) {
            self.wishlist.setWishlist(data);
          }
        } else if (userData?.wishlist) {
          self.wishlist.setWishlist(userData?.wishlist);
        }

        if (userData?.checkout) {
          const snapshot = {
            ...getSnapshot(checkout),
            ...userData?.checkout,
          };
          if (userData?.checkout?.id !== snapshot.id) {
            snapshot.id = null;
          }
          applySnapshot(checkout, snapshot);
        }

        self.loadingUserInfo = false;

        return {};
      }),

      refreshWishlist: flow(function* () {
        const wishlistId = self.wishlist?.id;

        if (wishlistId) {
          const { data } = (yield apolloManager.refreshWishlist(
            wishlistId,
            self.wishlist.productIds,
          )) as AsyncReturnType<typeof apolloManager['refreshWishlist']>;

          if (data?.productIds) {
            self.wishlist.setProductIds(data.productIds);
          }
        }
      }),
    };
  })
  .actions((self) => {
    const apollo = initializeApollo();
    const apolloManager = new ApolloClientManager(apollo);
    const checkout: CheckoutModel = (getParent(self) as RootModel).checkout;
    const cart: CartModel = (getParent(self) as RootModel).cart;

    return {
      setPassword: flow(function* (email: string, token: string, password: string) {
        const { data, error } = (yield apolloManager.setPassword(
          email,
          token,
          password,
        )) as AsyncReturnType<typeof apolloManager['setPassword']>;

        if (error?.length) {
          return {
            error,
          };
        }

        self.token = data.setPassword.token;
        self.csrfToken = data.setPassword.csrfToken;

        const { error: userError } = (yield self.setUserInfo()) as AsyncReturnType<
          typeof self['setUserInfo']
        >;

        return {
          error: userError,
          data,
        };
      }),

      resetPasswordRequest: flow(function* (email: string) {
        const { error } = (yield apolloManager.resetPasswordRequest(
          email,
          process.env.NEXT_PUBLIC_RESET_PASSWORD_REDIRECT_URL,
        )) as AsyncReturnType<typeof apolloManager['resetPasswordRequest']>;

        return {
          error,
        };
      }),

      signIn: flow(function* (email: string, password: string) {
        const { data, error } = (yield apolloManager.signIn(email, password)) as AsyncReturnType<
          typeof apolloManager['signIn']
        >;

        if (error) {
          return {
            error,
          };
        }

        try {
          if (CREDENTIAL_API_EXISTS) {
            yield navigator.credentials.store(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              new window.PasswordCredential({
                id: email,
                password,
              }),
            );
          }
        } catch (error) {
          console.warn('Unable to use credentials API', error);
        }

        self.token = data.token;
        self.csrfToken = data.csrfToken;

        const result = (yield self.setUserInfo()) as AsyncReturnType<typeof self['setUserInfo']>;

        return result;
      }),

      signOut: flow(function* () {
        applySnapshot(self, {});
        applySnapshot(checkout, {});
        applySnapshot(cart, {});

        if (LOCAL_STORAGE_EXISTS) {
          window.localStorage.removeItem('checkout');
          window.localStorage.removeItem('csrfToken');
          window.localStorage.removeItem('wishlist');
          window.localStorage.removeItem('token');
        }

        yield apolloManager.signOut();

        try {
          if (navigator.credentials?.preventSilentAccess) {
            yield navigator.credentials.preventSilentAccess();
          }
        } catch (credentialsError) {
          console.warn('Unable to use credentials API', credentialsError);
        }
      }),

      refreshSignInToken: flow(function* (refreshToken?: string) {
        if (!self.csrfToken && !refreshToken) {
          return {
            error: new Error('Refresh sign in token impossible. No refresh token received.'),
          };
        }

        self.tokenRefreshing = true;

        const { data, error } = (yield apolloManager.refreshSignInToken({
          csrfToken: self.csrfToken,
          refreshToken,
        })) as AsyncReturnType<typeof apolloManager['refreshSignInToken']>;

        if (error) {
          self.tokenRefreshing = false;

          return {
            error,
          };
        }

        self.token = data.token;
        self.tokenRefreshing = false;

        return {
          data,
        };
      }),

      createAddress: flow(function* (
        input: AccountAddressCreateVariables['input'],
        type?: AccountAddressCreateVariables['type'],
      ) {
        const { data, errors } = (yield apollo.mutate<
          AccountAddressCreate,
          AccountAddressCreateVariables
        >({
          mutation: accountAddressCreateMutation,
          variables: {
            input,
            type,
          },
        })) as FetchResult<AccountAddressCreate>;

        const dataError = errors?.length
          ? errors
          : data?.accountAddressCreate?.accountErrors || null;
        if (!dataError?.length) {
          self.user.addresses = cast(data.accountAddressCreate.user.addresses);
        }

        return {
          data,
          dataError,
        };
      }),

      updateCurrency: flow(function* (currency: string) {
        if (!self.isAuthenticated || !self.user?.id) {
          return;
        }

        const { data } = (yield apollo.mutate<UpdateMetadata, UpdateMetadataVariables>({
          mutation: updateMetadataMutation,
          variables: {
            id: self.user.id,
            input: [{ key: 'currency', value: currency }],
          },
        })) as FetchResult<UpdateMetadata>;

        if (data?.updateMetadata?.item?.metadata) {
          self.user.metadata = parseMetadata(data?.updateMetadata?.item?.metadata);
        }
      }),

      setData(user: UserType, token: string) {
        self.user = cast(omit(user, 'metadata'));
        self.user.metadata = parseMetadata(user?.metadata);
        self.token = cast(token);
      },

      setToken(token: string) {
        self.token = token;
      },

      updateAccount(firstName?: string, lastName?: string) {
        if (firstName) {
          self.user.firstName = firstName;
        }

        if (lastName) {
          self.user.lastName = lastName;
        }
      },

      setAddresses(addresses: UserType['addresses']) {
        self.user.addresses = cast(addresses);

        self.user.addresses.forEach((address) => {
          if (address.isDefaultBillingAddress) {
            self.user.defaultBillingAddress = address;
          }
          if (address.isDefaultShippingAddress) {
            self.user.defaultShippingAddress = address;
          }
        });
      },

      updateAddress(address: Address) {
        const index = self.user.addresses.findIndex((item) => item.id === address.id);

        if (index !== -1) {
          self.user.addresses[index] = address;
        }
      },

      removeAddress(address: Address) {
        const index = self.user.addresses.findIndex((item) => item.id === address.id);

        if (index !== -1) {
          self.user.addresses.splice(index, 1);
        }
      },

      setAddressAsDefaultById(id: string, type: AddressTypeEnum) {
        const index = self.user.addresses.findIndex((item) => item.id === id);

        if (index !== -1) {
          if (type === AddressTypeEnum.BILLING) {
            self.user.addresses[index] = {
              ...self.user.addresses[index],
              isDefaultBillingAddress: true,
            };
            self.user.defaultBillingAddress = self.user.addresses[index];
          } else if (type === AddressTypeEnum.SHIPPING) {
            self.user.addresses[index] = {
              ...self.user.addresses[index],
              isDefaultShippingAddress: true,
            };
            self.user.defaultShippingAddress = self.user.addresses[index];
          }
        }
      },

      setAddressAsDefault(address: Address, type: AddressTypeEnum) {
        const index = self.user.addresses.findIndex((item) => item.id === address.id);

        if (index !== -1) {
          self.user.addresses[index] = address;
          if (type === AddressTypeEnum.BILLING) {
            self.user.defaultBillingAddress = address;
          } else if (type === AddressTypeEnum.SHIPPING) {
            self.user.defaultShippingAddress = address;
          }
        }
      },
    };
  })
  .actions((self) => {
    const apollo = initializeApollo();
    const apolloManager = new ApolloClientManager(apollo);

    return {
      registerAccount: flow(function* (email: string, password: string, redirectUrl?: string) {
        const { data, error } = (yield apolloManager.registerAccount(
          email,
          password,
        )) as AsyncReturnType<typeof apolloManager['registerAccount']>;

        if (error?.length > 0) {
          return {
            error,
          };
        }

        if (!data?.requiresConfirmation) {
          const { error: signInError } = yield self.signIn(email, password);

          if (signInError && signInError?.length > 0) {
            return {
              error: signInError,
            };
          }

          return {
            signedIn: true,
          };
        }

        return {
          data,
          error,
        };
      }),

      autoSignIn: flow(function* () {
        let credentials: Credential;

        try {
          credentials = (yield navigator.credentials.get({
            password: true,
          })) as Credential;

          if (credentials && credentials.password) {
            const { error } = yield self.signIn(credentials.id, credentials.password);

            return {
              error,
            };
          }
        } catch (credentialsError) {
          console.warn('Unable to use credentials API', credentialsError);
        }
      }),

      verifyToken: flow(function* () {
        const { data, error } = (yield apolloManager.verifySignInToken({
          token: self.token,
        })) as AsyncReturnType<typeof apolloManager['verifySignInToken']>;

        if (error || !data?.isValid) {
          yield self.signOut();
          try {
            if (typeof window !== 'undefined' && navigator.credentials?.preventSilentAccess) {
              yield navigator.credentials.preventSilentAccess();
            }
          } catch (e) {
            console.warn(e);
          }

          return false;
        }

        return true;
      }),
    };
  })
  .actions((self) => ({
    afterCreate: flow(function* () {
      self.loading = true;
      try {
        if (self.token) {
          const tokenValid = yield self.verifyToken();
          if (!tokenValid) {
            self.loading = false;
            return;
          }

          const { error } = (yield self.setUserInfo()) as AsyncReturnType<
            typeof self['setUserInfo']
          >;

          if (error && CREDENTIAL_API_EXISTS) {
            yield self.signOut();
            try {
              if (typeof window !== 'undefined' && navigator.credentials?.preventSilentAccess) {
                yield navigator.credentials.preventSilentAccess();
              }
            } catch (e) {
              console.warn(e);
            } finally {
              self.loading = false;
            }
          }
        } else if (CREDENTIAL_API_EXISTS) {
          yield self.autoSignIn();

          if (!self.isAuthenticated) {
            yield self.refreshWishlist();
          }
        }
        self.loading = false;
      } catch (e) {
        self.loading = false;
        throw e;
      } finally {
        self.loading = false;
      }
    }),
  }));

export interface AuthModel extends Instance<typeof Auth> {}
