import { gql } from '@apollo/client';

import { userFragment } from '../fragments/auth';
import { accountErrorFragment } from '../fragments/errors';

export const tokenAuthMutation = gql`
  ${accountErrorFragment}
  ${userFragment}
  mutation TokenAuth($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      token
      csrfToken
      errors: accountErrors {
        ...AccountError
      }
      user {
        ...User
      }
    }
  }
`;

export const tokenVeryficationMutation = gql`
  ${accountErrorFragment}
  mutation VerifyToken($token: String!) {
    tokenVerify(token: $token) {
      accountErrors {
        ...AccountError
      }
      payload
      isValid
      user {
        id
      }
    }
  }
`;

export const accountRegisterMutation = gql`
  ${accountErrorFragment}
  ${userFragment}
  mutation AccountRegister(
    $email: String!
    $password: String!
    $shopUrl: String
    $redirectUrl: String
  ) {
    accountRegister(
      input: { email: $email, password: $password, redirectUrl: $redirectUrl, shopUrl: $shopUrl }
    ) {
      accountErrors {
        ...AccountError
      }
      requiresConfirmation
      user {
        ...User
      }
    }
  }
`;

export const requestPasswordResetMutation = gql`
  ${accountErrorFragment}
  mutation RequestPasswordReset($email: String!, $redirectUrl: String!) {
    requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {
      accountErrors {
        ...AccountError
      }
    }
  }
`;

export const tokenRefreshMutation = gql`
  ${accountErrorFragment}
  ${userFragment}
  mutation TokenRefresh($refreshToken: String!, $csrfToken: String) {
    tokenRefresh(refreshToken: $refreshToken, csrfToken: $csrfToken) {
      token
      user {
        ...User
      }
      accountErrors {
        ...AccountError
      }
    }
  }
`;

export const deactivateAllUserTokensMutation = gql`
  ${accountErrorFragment}
  mutation DeactivateAllUserTokens {
    tokensDeactivateAll {
      accountErrors {
        ...AccountError
      }
    }
  }
`;
