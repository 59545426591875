import { Button } from '@chakra-ui/button';
import { Box, Flex, Stack, Text } from '@chakra-ui/layout';
import { Fade } from '@chakra-ui/transition';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useLocalStorage } from 'react-use';

import Link from 'components/link';
import { useLocalizedLink } from 'hooks/useLocalizedLink';

const CookieConsent: React.FC = () => {
  const [cookieConsent, setCookieConsent] = useLocalStorage('cookie-consent', false);
  const { t } = useTranslation('common');
  const { isReady } = useRouter();
  const localizedLink = useLocalizedLink();

  const handleClose = () => {
    setCookieConsent(true);
  };

  if (typeof window === 'undefined' || !isReady) return null;

  return (
    <Fade
      in={!cookieConsent}
      style={{ zIndex: 20, position: 'fixed', bottom: 0, width: '100%' }}
      unmountOnExit
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        w="full"
        px={{ base: 6, md: 12 }}
        py="4"
        bg="blackAlpha.900"
        color="white"
        justify="center"
        align="center"
      >
        <Box maxW="4xl">
          <Text fontSize="sm">
            <Trans
              i18nKey="common:cookies"
              components={[
                <Link
                  href={localizedLink('terms')}
                  color="white"
                  textDecor="underline"
                  _hover={{ color: 'white', textDecor: 'none' }}
                />,
              ]}
            />
          </Text>
        </Box>
        <Stack direction="row" ml={{ base: 0, md: 4 }} mt={{ base: 4, md: 0 }}>
          <Button
            variant="outline"
            borderColor="white"
            color="white"
            size="sm"
            onClick={handleClose}
          >
            {t('agree')}
          </Button>
          <Button
            variant="outline"
            borderColor="white"
            color="white"
            size="sm"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
        </Stack>
      </Flex>
    </Fade>
  );
};

export default CookieConsent;
