import { extendTheme, ThemeOverride } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { createBreakpoints } from '@chakra-ui/theme-tools';

import Accordion from './components/accordion';
import Alert from './components/alert';
import Breadcrumb from './components/breadcrumb';
import Button from './components/button';
import Checkbox from './components/checkbox';
import CloseButton from './components/close-button';
import FormLabel from './components/form-label';
import Heading from './components/heading';
import Input from './components/input';
import Link from './components/link';
import Menu from './components/menu';
import Modal from './components/modal';
import NumberInput from './components/number-input';
import Progress from './components/progress';
import Radio from './components/radio';
import Select from './components/select';
import Skeleton from './components/skeleton';
import Spinner from './components/spinner';
import Tabs from './components/tabs';
import Textarea from './components/textarea';

const global = (props) => ({
  'html, body': {
    fontFamily: 'body',
    color: mode('black', 'whiteAlpha.900')(props),
    bg: '#f8f8f8',
    lineHeight: 'base',
    '*::placeholder': {
      color: mode('gray.400', 'whiteAlpha.400')(props),
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word',
    },
    fontFeatureSettings: `"pnum"`,
    fontVariantNumeric: 'proportional-nums',
  },
  html: {
    height: '-webkit-fill-available',
  },
  body: {
    minHeight: '-webkit-fill-available',
  },
  '.swiper-container.swiper-container-pagination': {
    pb: 12,
  },
  '.swiper-slide': {
    WebkitTransform: 'translate3d(0, 0, 0)',
  },
  '.swiper-container-thumbs .swiper-slide': {
    border: '2px solid',
    borderColor: 'transparent',
    cursor: 'pointer',
  },
  '.swiper-container-thumbs .swiper-slide-thumb-active': {
    borderColor: 'primary',
  },
  '.slick-track': {
    margin: 0,
  },
  '.chakra-alert': {
    borderRadius: '0 !important',
  },
  '.rich-text': {
    p: {
      mb: 4,
    },
    a: {
      transition: 'all 250ms',
      fontWeight: 'bold',
      textDecoration: 'underline',
      _hover: {
        color: 'primary',
        textDecoration: 'none',
      },
    },
    h3: {
      fontWeight: 'bold',
      mt: 2,
      mb: 0,
    },
    ul: {
      pl: 5,
    },
    ol: {
      pl: 5,
    },
  },
  '.block-content-alert p': {
    color: 'white',
    mb: 0,
  },
  '.block-content-alert a': {
    color: 'white',
    textDecoration: 'underline',
  },
  '.block-content-alert a:hover': {
    color: 'white',
    textDecoration: 'none',
  },
  '.chakra-collapsible-text': {
    position: 'relative',
  },
  '.chakra-collapsible-text::after': {
    content: '""',
    position: 'absolute',
    transition: '.5 ease',
    opacity: 0,
    bottom: 0,
    left: 0,
    right: 0,
    h: 6,
    bg: 'linear-gradient(180deg, hsla(0,0%,100%,.3), #fff)',
  },
  '.chakra-collapsible-text.category::after': {
    bg: 'linear-gradient(180deg, hsla(0,0%,100%,.3), #f8f8f8)',
  },
  '.chakra-collapsible-text.collapsed::after': {
    opacity: 1,
  },
  '.hero-breadcrumb .chakra-breadcrumb__list': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  'header.header--transparent': {
    background: 'transparent',
    boxShadow: 'none',
  },
  'header.header--white': {
    '.menu__top-item > .chakra-link, .chakra-link--white': {
      color: colors.bodyBg,
      '&::after': {
        borderBottomColor: colors.bodyBg,
      },

      svg: {
        fill: colors.bodyBg,
      },
    },
    '.hamburger-react': {
      color: 'white',
    },
  },
  '.input__hero': {
    zIndex: 6,
  },
  '.hero-wrapper': {
    height: 'auto !important',
  },
  '.hero-wrapper--under-header': {
    '@media': {
      marginTop: '-90px',
    },
  },
  '.hero-video': {
    position: 'relative',
    height: '100%',
    marginTop: '-90px',
    overflow: 'hidden',
    '.hero-video__link': {
      position: 'relative',
      display: 'block',
    },
    '.hero-video__element': {},
    '.hero-video__overlay': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1,
      '&--opaque': {
        background: 'rgba(12, 13, 17, 0.75) none repeat scroll 0% 0%',
      },
    },
  },
});

const colors = {
  primary: '#059669',
  bodyBg: '#f8f8f8',
  border: '#ccc',
  lightText: 'white',
  darkText: 'black',
  grayText: '#a8a8a8',
  darkGrayText: '#7d7b7b',
  darkBackground: '#f2f2f2',
  button: '#4D4D4D',
  overlay: 'rgba(213, 213, 213, 0.6)',
  darkGray: {
    50: '#F7F7F7',
    100: '#EEEEEE',
    200: '#D5D5D5',
    300: '#BBBBBB',
    400: '#888888',
    500: '#555555',
    600: '#4D4D4D',
    700: '#333333',
    800: '#262626',
    900: '#1A1A1A',
  },
  brand: {
    100: '#FEFDFB',
    200: '#F8E9CE',
    300: '#F2D5A1',
    400: '#EBC275',
    500: '#E5AE48',
    600: '#DB991F',
    700: '#AE7A19',
    800: '#815B12',
    900: '#553B0C',
  },
};

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '1670px',
});

const theme = {
  breakpoints,
  sizes: {
    '2xl': '1440px',
    '3xl': '1670px',
    container: {
      '2xl': '1440px',
      '3xl': '1670px',
    },
  },
  fonts: {
    body:
      'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading:
      'Play, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  fontSizes: {
    sm: '0.9375rem',
    '2xl': '1.375rem',
    '7xl': '6rem',
  },
  letterSpacings: {
    superWide: '0.2em',
  },
  shadows: {
    menuPopover: '7px 6px 40px 0px rgb(204 204 223 / 16%)',
    outline: '0 0 0 3px rgba(5, 150, 105, 0.6)',
  },
  colors: {
    ...colors,
  },
  styles: {
    global,
  },
  components: {
    Accordion,
    Alert,
    Button,
    Breadcrumb,
    Checkbox,
    Link,
    Input,
    FormLabel,
    Heading,
    Modal,
    CloseButton,
    Tabs,
    Select,
    NumberInput,
    Menu,
    Progress,
    Radio,
    Skeleton,
    Spinner,
    Textarea,
  },
  textStyles: {
    titleSmall: {
      fontSize: {
        base: 'md',
        lg: 'lg',
      },
      fontWeight: 'semibold',
      letterSpacing: 'superWide',
      fontFamily: 'heading',
    },
    titleBig: {
      fontSize: {
        base: '4xl',
        md: '5xl',
        lg: '6xl',
        xl: '7xl',
      },
      fontWeight: 'normal',
      fontFamily: 'heading',
    },
    cardTitle: {
      fontSize: {
        base: 'lg',
        md: 'xl',
        lg: '2xl',
      },
      textTransform: 'uppercase',
    },
    mobileMenuItem: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    mobileMenuItemBack: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    navItem: {
      textTransform: 'uppercase',
      fontWeight: 'semibold',
    },
  },
  layerStyles: {
    mobileNavLink: {
      w: 'full',
      p: 4,
      display: 'inline-flex',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      justifyContent: 'flex-start',
      borderBottom: '1px',
      borderColor: 'darkGray.100',
      WebkitTapHighlightColor: 'transparent',
      _hover: {
        boxShadow: 'none',
        WebkitTapHighlightColor: 'transparent',
      },
      _active: {
        boxShadow: 'none',
        WebkitTapHighlightColor: 'transparent',
      },
      _focus: {
        boxShadow: 'none',
        WebkitTapHighlightColor: 'transparent',
      },
    },
    buttonDark: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'black',
      color: 'black',
      _hover: {
        bg: 'black',
        color: 'white',
      },
      _focus: {
        bg: 'black',
        color: 'white',
      },
    },
    menuHeader: {
      fontWeight: 'semibold',
      position: 'relative',
      color: 'black',
      pb: 2,
      _after: {
        content: '""',
        borderBottom: '1px',
        borderColor: 'black',
        position: 'absolute',
        left: 0,
        bottom: 0,
        w: '50%',
        minW: '20px',
      },
    },
  },
};

export default extendTheme((theme as unknown) as ThemeOverride);
