/* eslint-disable @typescript-eslint/no-unsafe-return */

import { JsonObject } from 'type-fest';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function JSONParseSafe(str: string): any | null {
  try {
    return JSON.parse(str);
  } catch (_e) {
    return null;
  }
}

export function parseMetadata(metadata: { key: string; value: string }[]): JsonObject {
  if (!metadata) {
    return {};
  }

  return metadata.reduce((acc, item) => {
    acc[item.key] = JSONParseSafe(item.value) || item.value;
    return acc;
  }, {});
}
