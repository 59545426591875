import { gql } from '@apollo/client';

import { checkoutPriceFragment } from './checkout';

export const baseProduct = gql`
  fragment BaseProduct on Product {
    id
    name
    slug
    thumbnail {
      url
      alt
    }
    thumbnail2x: thumbnail(size: 540) {
      url
    }
    translation {
      name
      slug
    }
  }
`;

export const selectedAttributeFragment = gql`
  fragment SelectedAttributeFields on SelectedAttribute {
    attribute {
      id
      name
      slug
      translation {
        name
      }
    }
    values {
      id
      name
      translation {
        name
      }
    }
  }
`;

export const productVariantFragment = gql`
  ${checkoutPriceFragment}
  fragment ProductVariantFields on ProductVariant {
    id
    sku
    name
    trackInventory
    quantityAvailable(countryCode: $countryCode)
    isAvailable
    pricing {
      onSale
      priceUndiscounted {
        ...Price
      }
      price {
        ...Price
      }
    }
    images {
      id
      url
      alt
    }
    attributes {
      attribute {
        id
        name
        slug
        translation {
          name
        }
      }
      values {
        id
        name
        value
        slug
      }
    }
  }
`;

export const attributeFragment = gql`
  fragment Attribute on Attribute {
    id
    name
    slug
    translation {
      name
    }
    values {
      id
      name
      slug
      value
      translation {
        name
      }
    }
  }
`;

export const categoryFragment = gql`
  fragment Category on Category {
    id
    name
    slug
    translation {
      name
      slug
    }
  }
`;

export const translatedProductFragment = gql`
  fragment TranslatedProduct on ProductTranslation {
    name
    slug
    description
    seoTitle
    seoDescription
    descriptionJson
  }
`;
