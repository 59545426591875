import { gql } from '@apollo/client';

import { wishlistFragment } from '../fragments/wishlist';

export const wishlistDetails = gql`
  ${wishlistFragment}
  query WishlistDetails($id: ID, $first: Int, $last: Int, $after: String, $before: String) {
    wishlist(id: $id) {
      ...Wishlist
    }
  }
`;

export const wishlistProductsQuery = gql`
  query WishlistProducts($id: ID) {
    wishlist(id: $id) {
      productIds
    }
  }
`;
