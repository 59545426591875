export default {
  variants: {
    product: {
      link: {
        _hover: {
          textDecoration: 'none',
          color: 'primary',
        },
      },
    },
  },
};
