module.exports = {
  locales: ['it', 'en'],
  defaultLocale: 'it',
  // loader: false,
  pages: {
    '*': ['common'],
    '/': ['home', 'news'],
    '/magazine': ['news'],
    '/magazine/[slug]': ['news'],
    '/magazine/category/[slug]': ['news'],
    '/account': ['account'],
    '/order/[number]': ['account'],
    '/login': ['account'],
    'rgx:^/account': ['account'],
    '/account-confirmation': ['account'],
    '/shop': ['shop'],
    '/shop/[slug]': ['shop', 'product'],
    '/[slug]': ['shop'],
    '/cart': ['cart'],
    '/contact': ['contact'],
    '/support': ['contact'],
    '/sell': ['sell'],
    '/work-with-us': ['work-with-us'],
    'rgx:^/checkout': ['checkout', 'account'],
  },
  // When loader === false, then loadLocaleFrom is required
  // loadLocaleFrom: (locale, namespace) =>
  //   import(`./locales/${locale}/${namespace}`).then((m) => m.default),
};
