import { useState } from 'react';

import { ProductDetails_product_variants_attributes_values } from 'lib/graphql/types/ProductDetails';
import { ProductVariantsAttributes } from './useProductVariantsAttributes';

export interface ProductVariantsAttributesSelectedValues {
  [key: string]: ProductDetails_product_variants_attributes_values | null;
}

export type ProductVariantsAttributesValuesSelection = [
  ProductVariantsAttributesSelectedValues,
  (
    selectedProductVariantsAttributeId: string,
    selectedProductVariantsAttributeValue: string | null,
  ) => void,
];

export function useProductVariantsAttributesValuesSelection(
  productVariantsAttributes: ProductVariantsAttributes,
): ProductVariantsAttributesValuesSelection {
  const [
    productVariantsAttributesSelectedValues,
    setProductVariantsAttributesSelectedValues,
  ] = useState<ProductVariantsAttributesSelectedValues>({});

  const selectProductVariantsAttributesValue = (
    selectedProductVariantsAttributeId: string,
    selectedProductVariantsAttributeValue: string | null,
  ) => {
    setProductVariantsAttributesSelectedValues((prevVariantsAttributesSelectedValue) => {
      const newVariantsAttributesSelectedValue: ProductVariantsAttributesSelectedValues = {};

      Object.keys(productVariantsAttributes).forEach((productVariantsAttributeId) => {
        if (productVariantsAttributeId === selectedProductVariantsAttributeId) {
          let selectedValue = null;
          if (selectedProductVariantsAttributeValue) {
            selectedValue =
              productVariantsAttributes[productVariantsAttributeId].values.find(
                (value) => value?.name === selectedProductVariantsAttributeValue,
              ) || null;
          }
          newVariantsAttributesSelectedValue[productVariantsAttributeId] = selectedValue;
        } else {
          newVariantsAttributesSelectedValue[productVariantsAttributeId] =
            prevVariantsAttributesSelectedValue[productVariantsAttributeId];
        }
      });

      return newVariantsAttributesSelectedValue;
    });
  };

  return [productVariantsAttributesSelectedValues, selectProductVariantsAttributesValue];
}
