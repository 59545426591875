export default {
  variants: {
    underline: {
      tab: {
        mb: '-2px',
        border: 'none',
        fontWeight: 'bold',
        fontSize: 'lg',
        position: 'relative',
        p: 0,
        _after: {
          borderBottom: '2px',
          borderColor: 'primary',
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: '2px',
          w: 'full',
          transition: 'all 0.2s linear 0s',
          transform: 'scale(0)',
        },
        _hover: {
          _after: {
            transform: 'scale(1)',
          },
        },
        _selected: {
          _after: {
            transform: 'scale(1)',
          },
        },
      },
      tabpanel: {
        px: 0,
        py: 10,
      },
    },
  },
};
