import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { createContext, FC, useCallback, useEffect, useRef, useState } from 'react';

import { useAuth } from 'hooks';
import { CURRENCY_SYMBOLS } from 'lib/consts';
import { ProductDetails_product_variants_attributes_values } from 'lib/graphql/types/ProductDetails';
import { LocalePaths } from 'types';

export type Size = 'EU' | 'US' | 'UK';

export interface ShopState {
  countries: string[];
  defaultCountry: string;
  currency: string;
  size: Size;
  defaultSize: 'EU';
  defaultCurrency: string;
  previousCurrency: string;
  availableCurrencies: string[];
  availableSizes: string[];
  displayGrossPrices: boolean;
  localePaths: LocalePaths;
  conversionRates: Record<string, number>;
  priceRanges: Record<string, { step: (min: number, max: number) => number }>;
}

export interface ShopActions {
  changeCurrency(currency: string, skipSave?: boolean): void;
  changeSize(size: Size): void;
  convertPrice(price: number): number;
  convertToDefaultCurrency(price: number): number;
  displayPrice(
    price: number,
    locale?: string,
    options?: Intl.NumberFormatOptions,
    skipConvert?: boolean,
  ): string;
  displaySize(attribueValue: ProductDetails_product_variants_attributes_values): string;
}

export const defaultContext: ShopState = {
  countries: [
    'AF',
    'AX',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AQ',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BQ',
    'BA',
    'BW',
    'BV',
    'BR',
    'IO',
    'BN',
    'BG',
    'BF',
    'BI',
    'CV',
    'KH',
    'CM',
    'CA',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CX',
    'CC',
    'CO',
    'KM',
    'CG',
    'CD',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CW',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'SZ',
    'ET',
    'EU',
    'FK',
    'FO',
    'FJ',
    'FI',
    'FR',
    'GF',
    'PF',
    'TF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GP',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'GY',
    'HT',
    'HM',
    'VA',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'YT',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'NC',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'KP',
    'MK',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RE',
    'RO',
    'RU',
    'RW',
    'BL',
    'SH',
    'KN',
    'LC',
    'MF',
    'PM',
    'VC',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SX',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'GS',
    'KR',
    'SS',
    'ES',
    'LK',
    'SD',
    'SR',
    'SJ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TL',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'UM',
    'US',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VG',
    'VI',
    'WF',
    'EH',
    'YE',
    'ZM',
    'ZW',
  ],
  defaultCountry: 'IT',
  displayGrossPrices: true,
  currency: 'EUR',
  size: 'EU',
  defaultSize: 'EU',
  defaultCurrency: 'EUR',
  previousCurrency: 'EUR',
  availableCurrencies: ['EUR', 'USD', 'CAD', 'AUD', 'GBP', 'JPY'],
  availableSizes: ['EU', 'US', 'UK'],
  priceRanges: {
    EUR: {
      step: (_min, max) => (max < 1000 ? 1 : 10),
    },
    USD: {
      step: (_min, max) => (max < 1000 ? 1 : 10),
    },
    CAD: {
      step: (_min, max) => (max < 1000 ? 1 : 10),
    },
    AUD: {
      step: (_min, max) => (max < 1000 ? 1 : 10),
    },
    GBP: {
      step: (_min, max) => (max < 1000 ? 1 : 10),
    },
    JPY: {
      step: (_min, max) => (max < 1000 ? 100 : 1000),
    },
  },
  localePaths: [],
  conversionRates: {},
};

export const ShopContext = createContext<(ShopState & ShopActions) | undefined>(undefined);

const ShopProvider: FC<{
  localePaths?: LocalePaths;
  conversionRates?: Record<string, number>;
}> = observer(({ localePaths = [], conversionRates = {}, children }) => {
  const router = useRouter();
  const ratesLoading = useRef(false);
  const [state, setState] = useState<ShopState>({
    ...defaultContext,
    currency: typeof window !== 'undefined' ? localStorage.getItem('currency') || 'EUR' : 'EUR',
    size:
      typeof window !== 'undefined' &&
      defaultContext.availableSizes.includes(localStorage.getItem('size'))
        ? (localStorage.getItem('size') as Size)
        : 'EU',
    conversionRates,
  });
  const [previousCurrency, setPreviousCurrency] = useState(state.currency);
  const auth = useAuth();

  useEffect(() => {
    if (!Object.keys(conversionRates)?.length && router.isFallback && !ratesLoading.current) {
      ratesLoading.current = true;
      fetch('/api/rates')
        .then((res) => res.json())
        .then((json) => {
          if (json) {
            setState((oldState) => ({
              ...oldState,
              conversionRates: json as Record<string, number>,
            }));
          }
        });
    }
  }, [conversionRates, router.isFallback]);

  const changeSize = useCallback(
    (size: Size) => {
      if (state.size !== size) {
        setState((oldState) => ({
          ...oldState,
          size,
        }));

        if (typeof window !== 'undefined') {
          localStorage.setItem('size', size);
        }
      }
    },
    [state.size],
  );

  const displaySize = useCallback(
    (attribueValue: ProductDetails_product_variants_attributes_values) => {
      if (!attribueValue.value || state.size === state.defaultSize) {
        return attribueValue.name;
      }

      if (attribueValue.value.toUpperCase().includes(`${state.size.toUpperCase()}:`)) {
        const sizes: Record<string, string> = attribueValue.value.split(';').reduce((acc, item) => {
          const tokens = item.split(':');
          acc[tokens[0]] = tokens[1];
          return acc;
        }, {});

        return sizes[state.size];
      }

      return attribueValue.name;
    },
    [state.defaultSize, state.size],
  );

  const convertPrice = useCallback(
    (price: number) => {
      if (
        state.currency === state.defaultCurrency ||
        !state.conversionRates[state.currency] ||
        Number.isNaN(state.conversionRates[state.currency])
      ) {
        return price;
      }

      return Math.ceil(price * state.conversionRates[state.currency]);
    },
    [state.conversionRates, state.currency, state.defaultCurrency],
  );

  const convertToDefaultCurrency = useCallback(
    (price: number) => {
      if (previousCurrency === state.defaultCurrency) {
        return price;
      }

      return price / state.conversionRates[previousCurrency];
    },
    [state.conversionRates, state.defaultCurrency, previousCurrency],
  );

  const changeCurrency = useCallback(
    (currency, skipSave?: boolean) => {
      if (
        currency !== state.currency &&
        state.availableCurrencies.includes(currency) &&
        ((state.conversionRates[currency] && !Number.isNaN(state.conversionRates[currency])) ||
          currency === state.defaultCurrency)
      ) {
        setPreviousCurrency(state.currency);
        setState((oldState) => ({
          ...oldState,
          currency,
        }));

        if (!skipSave) {
          auth.updateCurrency(currency);
        }

        if (typeof window !== 'undefined') {
          localStorage.setItem('currency', currency);
        }
      }
    },
    [state.currency, state.availableCurrencies, state.conversionRates, auth, state.defaultCurrency],
  );

  const displayPrice = useCallback(
    (price: number, locale?: string, options?: Intl.NumberFormatOptions, skipConvert = false) => {
      const convertedPrice = skipConvert ? price : convertPrice(price);

      const priceString = new Intl.NumberFormat(locale, {
        minimumFractionDigits: Number.isInteger(convertedPrice) ? 0 : 2,
        maximumFractionDigits: Number.isInteger(convertedPrice) ? 0 : 2,
        ...options,
      }).format(convertedPrice);

      const currentCurrency =
        state.conversionRates[state.currency] &&
        !Number.isNaN(state.conversionRates[state.currency])
          ? state.currency
          : state.defaultCurrency;
      return `${priceString} ${CURRENCY_SYMBOLS[currentCurrency] as string}`;
    },
    [state.currency, convertPrice, state.conversionRates, state.defaultCurrency],
  );

  useEffect(() => {
    if (
      auth.user?.metadata?.currency &&
      auth.user?.metadata?.currency !== state.currency &&
      state.availableCurrencies.includes(auth.user?.metadata?.currency as string)
    ) {
      changeCurrency(auth.user?.metadata?.currency, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return (
    <ShopContext.Provider
      value={{
        ...state,
        localePaths,
        changeSize,
        changeCurrency,
        displayPrice,
        convertPrice,
        convertToDefaultCurrency,
        displaySize,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
});

ShopProvider.displayName = 'ShopProvider';

export { ShopProvider };
