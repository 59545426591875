import { MutationHookOptions, useMutation as useApolloMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';

import { SaleorContext } from 'context/saleor';
import { accountRegisterMutation } from 'lib/graphql/mutations/auth';
import {
  accountAddressCreateMutation,
  accountAddressDeleteMutation,
  accountAddressUpdateMutation,
  accountSetDefaultAddressMutation,
  accountUpdateMutation,
  changeUserPasswordMutation,
  setPasswordMutation,
} from 'lib/graphql/mutations/user';
import {
  AccountAddressCreate,
  AccountAddressCreateVariables,
} from 'lib/graphql/types/AccountAddressCreate';
import {
  AccountAddressDelete,
  AccountAddressDeleteVariables,
} from 'lib/graphql/types/AccountAddressDelete';
import {
  AccountAddressUpdate,
  AccountAddressUpdateVariables,
} from 'lib/graphql/types/AccountAddressUpdate';
import { AccountRegister, AccountRegisterVariables } from 'lib/graphql/types/AccountRegister';
import {
  AccountSetDefaultAddress,
  AccountSetDefaultAddressVariables,
} from 'lib/graphql/types/AccountSetDefaultAddress';
import { AccountUpdate, AccountUpdateVariables } from 'lib/graphql/types/AccountUpdate';
import { PasswordChange, PasswordChangeVariables } from 'lib/graphql/types/PasswordChange';
import { SetPassword, SetPasswordVariables } from 'lib/graphql/types/SetPassword';
import { useSuccessToast } from './toast';
import { useMutation } from './useMutation';

function useAuth() {
  const rootState = useContext(SaleorContext);

  return rootState.auth;
}

function useRegisterMutation() {
  const [register, result] = useMutation<AccountRegister, AccountRegisterVariables>(
    accountRegisterMutation,
    {
      extractError: (data) => data?.accountRegister?.accountErrors,
    },
  );

  return {
    register,
    ...result,
  };
}

function useSetPasswordMutation() {
  const auth = useAuth();
  const router = useRouter();
  const toast = useSuccessToast();
  const { t } = useTranslation('common');

  const [setPassword, result] = useApolloMutation<SetPassword, SetPasswordVariables>(
    setPasswordMutation,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data?.setPassword?.token && data?.setPassword?.user) {
          auth.setData(data?.setPassword?.user, data?.setPassword?.token);
          router.push('/');
          toast({
            title: t('changePasswordForm.success'),
          });
        }
      },
    },
  );

  return [setPassword, result] as const;
}

function useAccountUpdateMutation() {
  const auth = useAuth();

  const [accountUpdate, result] = useMutation<AccountUpdate, AccountUpdateVariables>(
    accountUpdateMutation,
    {
      extractError: (data) => data?.accountUpdate?.errors,
      onSuccess: (data) => {
        auth.updateAccount(data.accountUpdate.user.firstName, data.accountUpdate.user.lastName);
      },
    },
  );

  return {
    accountUpdate,
    ...result,
  };
}

function usePasswordChangeMutation() {
  const [passwordChange, result] = useMutation<PasswordChange, PasswordChangeVariables>(
    changeUserPasswordMutation,
    {
      extractError: (data) => data?.passwordChange?.errors,
    },
  );

  return {
    passwordChange,
    ...result,
  };
}

function useAddressCreateMutation() {
  const auth = useAuth();

  const [addressCreate, result] = useApolloMutation<
    AccountAddressCreate,
    AccountAddressCreateVariables
  >(accountAddressCreateMutation, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data?.accountAddressCreate?.user?.addresses) {
        auth.setAddresses(data.accountAddressCreate.user.addresses);
      }
    },
  });

  return {
    addressCreate,
    ...result,
  };
}

function useAddressUpdateMutation(
  options?: MutationHookOptions<AccountAddressUpdate, AccountAddressUpdateVariables>,
) {
  const auth = useAuth();

  const [addressUpdate, result] = useApolloMutation<
    AccountAddressUpdate,
    AccountAddressUpdateVariables
  >(accountAddressUpdateMutation, {
    fetchPolicy: 'no-cache',
    ...options,
    onCompleted: (data) => {
      if (data?.accountAddressUpdate?.address) {
        auth.updateAddress(data.accountAddressUpdate.address);
      }

      options?.onCompleted?.(data);
    },
  });

  return {
    addressUpdate,
    ...result,
  };
}

function useAddressDeleteMutation(
  options?: MutationHookOptions<AccountAddressDelete, AccountAddressDeleteVariables>,
) {
  const auth = useAuth();

  const [addressDelete, result] = useApolloMutation<
    AccountAddressDelete,
    AccountAddressDeleteVariables
  >(accountAddressDeleteMutation, {
    fetchPolicy: 'no-cache',
    ...options,
    onCompleted: (data) => {
      if (data?.accountAddressDelete?.address) {
        auth.removeAddress(data.accountAddressDelete.address);
      }

      options?.onCompleted?.(data);
    },
  });

  return {
    addressDelete,
    ...result,
  };
}

function useSetDefaultAddressMutation(
  options?: MutationHookOptions<AccountSetDefaultAddress, AccountSetDefaultAddressVariables>,
) {
  const auth = useAuth();

  const [setDefaultAddress, result] = useApolloMutation<
    AccountSetDefaultAddress,
    AccountSetDefaultAddressVariables
  >(accountSetDefaultAddressMutation, {
    fetchPolicy: 'no-cache',
    ...options,
    onCompleted(data) {
      if (data?.accountSetDefaultAddress?.user?.addresses?.length) {
        auth.setAddresses(data?.accountSetDefaultAddress?.user?.addresses);
      }

      options?.onCompleted?.(data);
    },
  });

  return {
    setDefaultAddress,
    ...result,
  };
}

export {
  useAuth,
  useRegisterMutation,
  useSetPasswordMutation,
  useAccountUpdateMutation,
  usePasswordChangeMutation,
  useAddressCreateMutation,
  useAddressUpdateMutation,
  useAddressDeleteMutation,
  useSetDefaultAddressMutation,
};
