import { GraphQLError } from 'graphql';
import { cast } from 'mobx-state-tree';
import { NextRouter } from 'next/router';
import urlJoin from 'url-join';

import { AuthModel } from 'models/Auth';
import { CheckoutLineModel, CheckoutModel } from 'models/Checkout';
import {
  CheckoutAddress,
  CheckoutLine,
  GraphQLError as QueryError,
  MenuItem,
  MenuItemWithChunks,
  PaginationState,
} from 'types';
import { Checkout } from './graphql/types/Checkout';
import { AddressInput, CountryCode } from './graphql/types/globalTypes';
import { AddressValidator } from './validators';

export const defaultCurrency = 'EUR';
export const localeCurrencyMap = {
  [CountryCode['US']]: 'USD',
};

export function getLocaleCurrency(locale?: string): string {
  return (localeCurrencyMap[locale] ?? defaultCurrency) as string;
}

export function filterNotEmptyArrayItems<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function getGraphQLError<TError extends QueryError>(
  graphqlError?: GraphQLError[],
  queryError?: TError[],
): GraphQLError[] | TError[] | null {
  if (graphqlError) {
    return graphqlError;
  }

  if (queryError) {
    return queryError;
  }

  return null;
}

export function sortCheckoutLines(a: CheckoutLine, b: CheckoutLine) {
  if (a.id && b.id) {
    const aId = a.id?.toUpperCase() || '';
    const bId = b.id?.toUpperCase() || '';
    return aId < bId ? -1 : aId > bId ? 1 : 0;
  }
  const aId = a.variant.id?.toUpperCase() || '';
  const bId = b.variant.id?.toUpperCase() || '';
  return aId < bId ? -1 : aId > bId ? 1 : 0;
}

export const maybe = <T>(exp: () => T, d?: T) => {
  try {
    const result = exp();
    return result === undefined ? d : result;
  } catch {
    return d;
  }
};

export function buildAddress(address: CheckoutAddress): AddressInput {
  if (!address) return null;

  return {
    city: address.city,
    country: CountryCode[address.country?.code],
    countryArea: address.countryArea,
    firstName: address.firstName,
    lastName: address.lastName,
    phone: address.phone,
    postalCode: address.postalCode,
    streetAddress1: address.streetAddress1,
    streetAddress2: address.streetAddress2,
  };
}

export function wrapFieldInContext(fieldName: string, context?: string) {
  return context ? `${context}[${fieldName}]` : fieldName;
}

export function buildCheckoutModel({
  id,
  token,
  email,
  shippingAddress,
  billingAddress,
  discount,
  discountName,
  voucherCode,
  lines,
  availablePaymentGateways,
  availableShippingMethods,
  shippingMethod,
  canShowVoucherField,
}: Checkout): CheckoutModel {
  return cast<CheckoutModel>({
    availablePaymentGateways,
    availableShippingMethods: availableShippingMethods?.filter(filterNotEmptyArrayItems) ?? [],
    billingAddress,
    email,
    id,
    lines: lines
      ?.filter((line) => line?.quantity && line.variant.id)
      .map<CheckoutLineModel>((line) => {
        const variant = line?.variant;

        return cast<CheckoutLineModel>({
          id: line.id,
          quantity: line.quantity,
          totalPrice: line.totalPrice,
          variant: {
            attributes: variant.attributes,
            id: variant.id,
            isAvailable: variant.quantityAvailable > 0,
            name: variant.name,
            pricing: variant.pricing,
            product: variant.product,
            quantityAvailable: variant.quantityAvailable,
            sku: variant.sku,
          },
        });
      }),
    promoCodeDiscount: {
      discount,
      discountName,
      voucherCode,
    },
    shippingAddress,
    shippingMethod,
    token,
    canShowVoucherField,
  });
}

export function addQueryString(url: string, router: NextRouter) {
  const queryString = new URLSearchParams(router.query as Record<string, string>).toString();
  const tokens = url.split('?');
  return [tokens[0], tokens[1] ? `${tokens[1]}&${queryString}` : queryString].join('?');
}

export function addParamsToRouter(router: NextRouter, params: Record<string, string>) {
  const query = new URLSearchParams(router.query as Record<string, string>);
  Object.keys(params).forEach((key) => {
    if (query.has(key)) {
      query.set(key, params[key]);
    } else {
      query.append(key, params[key]);
    }
  });
  const queryString = query.toString();
  return [router.pathname, queryString].filter(Boolean).join('?');
}

export function getCheckoutStepPath(auth: AuthModel, checkout: CheckoutModel, router: NextRouter) {
  const shippingValid = AddressValidator.safeParse(buildAddress(checkout.shippingAddress));
  const billingValid = AddressValidator.safeParse(buildAddress(checkout.billingAddress));
  const pathName = router.pathname;

  if (
    !auth.isAuthenticated &&
    ((checkout.isShippingRequired && !shippingValid.success) || !billingValid.success) &&
    pathName === '/checkout'
  ) {
    return '/checkout';
  }

  if ((checkout.isShippingRequired && !shippingValid.success) || !billingValid.success) {
    return '/checkout/address';
  } else if (pathName === '/checkout/address') {
    return '/checkout/address';
  }

  if (checkout.isShippingRequired && !checkout.shippingMethod) {
    return '/checkout/shipping';
  } else if (pathName === '/checkout/shipping') {
    return '/checkout/shipping';
  }

  return '/checkout/payment';
}

export function cleanObject(obj: Record<string, unknown>) {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
}

export function buildMenuItemWithChunks(item: MenuItem, perChunk: number): MenuItemWithChunks {
  return {
    ...item,
    children: buildMenuItemChunks(item.children, perChunk),
  };
}

export function buildMenuItemChunks(items: MenuItem[], perChunk: number): MenuItem[][] {
  return items.reduce<MenuItem[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function createPaginationState(
  paginateBy: number,
  queryString: Record<string, string>,
): PaginationState {
  return queryString && (queryString.before || queryString.after)
    ? queryString.after
      ? {
          after: queryString.after,
          first: paginateBy,
        }
      : {
          before: queryString.before,
          last: paginateBy,
        }
    : {
        first: paginateBy,
      };
}

export function buildAbsoluteUrl(...paths: string[]) {
  if (paths[0].indexOf('http') !== 0) {
    paths.unshift(process.env.NEXT_PUBLIC_SHOP_URL);
  }
  return urlJoin(...paths);
}
