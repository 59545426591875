import { flow, getParent, Instance, types as t } from 'mobx-state-tree';

import { initializeApollo } from 'lib/apollo';
import { ApolloClientManager } from 'lib/apollo-manager';
import { sortCheckoutLines } from 'lib/utils';
import { AsyncReturnType } from 'types';
import { CheckoutModel } from './Checkout';
import { RootModel } from './Root';

const Cart = t
  .model('Cart', {})
  .actions((self) => {
    const checkout: CheckoutModel = (getParent(self) as RootModel).checkout;
    const apollo = initializeApollo();
    const apolloManager = new ApolloClientManager(apollo);

    return {
      setCartItem: flow(function* () {
        if (checkout.id && checkout.lines?.length) {
          const { data, error } = (yield apolloManager.setCartItem(
            checkout.id,
            checkout.lines,
          )) as AsyncReturnType<typeof apolloManager['setCartItem']>;

          if (error) {
            return {
              error,
            };
          }

          if (data) {
            checkout.setCheckout(data);
          }
        }

        return {};
      }),
    };
  })
  .actions((self) => {
    const checkout: CheckoutModel = (getParent(self) as RootModel).checkout;
    const apollo = initializeApollo();
    const apolloManager = new ApolloClientManager(apollo);

    return {
      addItem: flow(function* (variantId: string, quantity: number) {
        checkout.addLine(variantId, quantity);

        if (checkout.lines?.length) {
          const { data, error } = (yield apolloManager.getRefreshedCheckoutLines(
            checkout.lines,
          )) as AsyncReturnType<typeof apolloManager['getRefreshedCheckoutLines']>;

          if (error) {
            return {
              error,
            };
          }

          checkout.updateLines(data);
        }

        if (checkout.id) {
          const { error } = (yield self.setCartItem()) as AsyncReturnType<
            typeof self['setCartItem']
          >;

          if (error) {
            return {
              error,
            };
          }
        }

        return {};
      }),

      removeItem: flow(function* (variantId: string) {
        checkout.removeLine(variantId);

        if (checkout.lines?.length) {
          const { data, error } = (yield apolloManager.getRefreshedCheckoutLines(
            checkout.lines,
          )) as AsyncReturnType<typeof apolloManager['getRefreshedCheckoutLines']>;

          if (error) {
            return {
              error,
            };
          }

          checkout.updateLines(data);
        }

        if (checkout.id) {
          const { error } = (yield self.setCartItem()) as AsyncReturnType<
            typeof self['setCartItem']
          >;

          if (error) {
            return {
              error,
            };
          }
        }

        return {};
      }),

      subtractItem: flow(function* (variantId: string) {
        checkout.decrementLine(variantId);

        if (checkout.lines?.length) {
          const { data, error } = (yield apolloManager.getRefreshedCheckoutLines(
            checkout.lines,
          )) as AsyncReturnType<typeof apolloManager['getRefreshedCheckoutLines']>;

          if (error) {
            return {
              error,
            };
          }

          checkout.updateLines(data);
        }

        if (checkout.id) {
          const { error } = (yield self.setCartItem()) as AsyncReturnType<
            typeof self['setCartItem']
          >;

          if (error) {
            return {
              error,
            };
          }
        }

        return {};
      }),

      updateItem: flow(function* (variantId: string, quantity: number) {
        checkout.updateLine(variantId, quantity);

        if (checkout.lines?.length) {
          const { data, error } = (yield apolloManager.getRefreshedCheckoutLines(
            checkout.lines,
          )) as AsyncReturnType<typeof apolloManager['getRefreshedCheckoutLines']>;

          if (error) {
            return {
              error,
            };
          }

          checkout.updateLines(data);
        }

        if (checkout.id) {
          const { error } = (yield self.setCartItem()) as AsyncReturnType<
            typeof self['setCartItem']
          >;

          if (error) {
            return {
              error,
            };
          }
        }

        return {};
      }),
    };
  })
  .views((self) => {
    const root = getParent(self) as RootModel;

    return {
      get items() {
        return root.checkout.lines
          ?.filter((line) => line.quantity > 0 && line.variant.sku)
          .sort(sortCheckoutLines);
      },
      get totalPricing() {
        return root.checkout.totalPricing;
      },
      get summaryPrices() {
        return root.checkout.summaryPrices;
      },
    };
  });

export { Cart };
export interface CartModel extends Instance<typeof Cart> {}
