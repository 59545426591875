/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloProvider, NormalizedCacheObject, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/node';
import omit from 'lodash/omit';
import { onSnapshot } from 'mobx-state-tree';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { createContext, FC, useEffect, useRef } from 'react';

import { useApollo } from 'hooks';
import { useErrorToast } from 'hooks/toast';
import { AuthModel, Wishlist } from 'models/Auth';
import { Checkout } from 'models/Checkout';
import { Root, RootModel } from 'models/Root';

interface SaleorProviderProps {
  initialApolloState?: NormalizedCacheObject;
}

export const SaleorContext = createContext<RootModel | null>(null);

function maybeGetCheckoutFromLocalStorage() {
  if (typeof window === 'undefined') return {};

  try {
    const json = JSON.parse(localStorage.getItem('checkout'));
    return Checkout.is(json) ? json : {};
  } catch (_e) {
    return {};
  }
}

function maybeGetWishlistFromLocalStorage() {
  if (typeof window === 'undefined') return {};

  try {
    const json = JSON.parse(localStorage.getItem('wishlist'));
    return Wishlist.is(json) ? json : {};
  } catch (_e) {
    return {};
  }
}

async function handleTokenExpiration(auth: AuthModel) {
  if (!auth.tokenRefreshing) {
    const { data, error } = await auth.refreshSignInToken();
    if (!data?.token || error) {
      await auth.signOut();
    }
  }
}

const SaleorProvider: FC<SaleorProviderProps> = ({ initialApolloState, children }) => {
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const toast = useErrorToast();
  const rootStateRef = useRef<RootModel>(
    Root.create({
      auth: {
        token: typeof window === 'undefined' ? '' : window.localStorage.getItem('token'),
        csrfToken: typeof window === 'undefined' ? '' : window.localStorage.getItem('csrfToken'),
        wishlist: {
          id: maybeGetWishlistFromLocalStorage()?.id,
        },
      },
      cart: {},
      checkout: maybeGetCheckoutFromLocalStorage(),
    }),
  );

  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
    (window as any).rootState = rootStateRef.current;
  }

  const client = useApollo(initialApolloState, {
    tokenExpirationCallback: () => {
      handleTokenExpiration(rootStateRef.current.auth);
    },
    locale,
    links: [
      onError((error) => {
        const ex = error.networkError ? error.networkError : error.graphQLErrors?.[0];
        if (ex) {
          let storage = '';
          if (typeof window !== 'undefined') {
            storage = JSON.stringify({ ...localStorage });
          }
          Sentry.captureException(ex, {
            extra: {
              query: error.operation?.query,
              variables: error.operation?.variables,
              operation: error.operation?.operationName,
              response: error.response ? JSON.stringify(error.response) : null,
              stack: ex.stack,
              exResponse: (ex as ServerError).response
                ? JSON.stringify((ex as ServerError).response)
                : null,
              result: (ex as ServerError).result
                ? JSON.stringify((ex as ServerError).result)
                : null,
              localStorage: storage,
            },
          });
        }

        if (!error.networkError) {
          toast({
            description: t('generalError'),
          });
        }
      }),
    ],
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const disposeAuth = onSnapshot(rootStateRef.current.auth, (snapshot) => {
      if (snapshot.token) {
        localStorage.setItem('token', snapshot.token);
      } else {
        localStorage.removeItem('token');
      }
      if (snapshot.csrfToken) {
        localStorage.setItem('csrfToken', snapshot.csrfToken);
      } else {
        localStorage.removeItem('csrfToken');
      }
      localStorage.setItem('wishlist', JSON.stringify(snapshot.wishlist));
    });

    const disposeCheckout = onSnapshot(rootStateRef.current.checkout, (snapshot) => {
      localStorage.setItem('checkout', JSON.stringify(omit(snapshot, 'isRedirecting')));
    });

    return () => {
      disposeAuth();
      disposeCheckout();
    };
  }, [rootStateRef]);

  return (
    <SaleorContext.Provider value={rootStateRef.current}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </SaleorContext.Provider>
  );
};

SaleorProvider.displayName = 'SaleorProvider';

export { SaleorProvider };
