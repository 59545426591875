import { Instance, types as t } from 'mobx-state-tree';

import { Auth } from './Auth';
import { Cart } from './Cart';
import { Checkout } from './Checkout';

const Root = t.model('Root', {
  auth: Auth,
  checkout: Checkout,
  cart: Cart,
});

export { Root };
export interface RootModel extends Instance<typeof Root> {}
