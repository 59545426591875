import { gql } from '@apollo/client';

import { checkoutAddressFragment, checkoutFragment } from './checkout';

export const userFragment = gql`
  ${checkoutAddressFragment}
  ${checkoutFragment}
  fragment User on User {
    id
    email
    firstName
    lastName
    isStaff
    credit {
      amount
      currency
    }
    metadata {
      key
      value
    }
    defaultShippingAddress {
      ...Address
    }
    defaultBillingAddress {
      ...Address
    }
    addresses {
      ...Address
    }
    checkout {
      ...Checkout
    }
  }
`;
