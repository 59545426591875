import chakraTheme from '@chakra-ui/theme';
import merge from 'deepmerge';

const formLabel = merge(chakraTheme.components.FormLabel, {
  baseStyle: {
    // fontFamily: 'body',
  },
});

export default formLabel;
