const size = {
  lg: {
    borderRadius: 'none',
  },

  md: {
    borderRadius: 'none',
  },

  sm: {
    borderRadius: 'none',
  },
};

export default {
  sizes: {
    lg: {
      field: size.lg,
      icon: size.lg,
    },
    md: {
      field: size.md,
      icon: size.md,
    },
    sm: {
      field: size.sm,
      icon: size.sm,
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: 'black',
        _hover: {
          borderColor: 'black',
        },
        _focus: {
          borderColor: 'black',
        },
      },
      stepper: {
        borderColor: 'black',
        _hover: {
          borderColor: 'black',
        },
        _focus: {
          borderColor: 'black',
        },
      },
    },
  },
};
