import { gql } from '@apollo/client';

import { wishlistErrorFragment } from '../fragments/errors';

export const wishlistAddProductMutation = gql`
  ${wishlistErrorFragment}
  mutation WishlistAddProduct($id: ID, $productId: ID!) {
    wishlistAddProduct(id: $id, productId: $productId) {
      wishlist {
        id
        productIds
      }
      wishlistErrors {
        ...WishlistError
      }
    }
  }
`;

export const wishlistRemoveProductMutation = gql`
  ${wishlistErrorFragment}
  mutation WishlistRemoveProduct($id: ID, $productId: ID!) {
    wishlistRemoveProduct(id: $id, productId: $productId) {
      wishlist {
        id
        productIds
      }
      wishlistErrors {
        ...WishlistError
      }
    }
  }
`;

export const wishlistSyncProductsMutation = gql`
  ${wishlistErrorFragment}
  mutation WishlistSyncProducts($id: ID, $productIds: [ID!]!) {
    wishlistSyncProducts(id: $id, productIds: $productIds) {
      wishlist {
        id
        productIds
      }
      wishlistErrors {
        ...WishlistError
      }
    }
  }
`;
