import { ApolloClient, ApolloError, NormalizedCacheObject } from '@apollo/client';

import {
  Checkout as CheckoutModel,
  CheckoutAddress,
  CheckoutLine,
  Payment as PaymentModel,
} from 'types';
import { ApolloOptions, initializeApollo } from './apollo';
import {
  accountRegisterMutation,
  requestPasswordResetMutation,
  tokenAuthMutation,
  tokenRefreshMutation,
  tokenVeryficationMutation,
} from './graphql/mutations/auth';
import {
  addCheckoutPromoCode,
  checkoutAddCredit,
  checkoutRemoveCredit,
  completeCheckoutMutation,
  createCheckoutMutation,
  createCheckoutPaymentMutation,
  removeCheckoutPromoCode,
  updateCheckoutBillingAddressMutation,
  updateCheckoutBillingAddressWithEmailMutation,
  updateCheckoutLineMutation,
  updateCheckoutShippingAddressMutation,
  updateCheckoutShippingMethodMutation,
} from './graphql/mutations/checkout';
import { setPasswordMutation } from './graphql/mutations/user';
import { wishlistSyncProductsMutation } from './graphql/mutations/wishlist';
import {
  checkoutDetails,
  checkoutProductVariants,
  userCheckoutDetails,
} from './graphql/queries/checkout';
import { getUserDetailsQuery } from './graphql/queries/user';
import { wishlistProductsQuery } from './graphql/queries/wishlist';
import { AccountRegister, AccountRegisterVariables } from './graphql/types/AccountRegister';
import {
  AddCheckoutPromoCode,
  AddCheckoutPromoCodeVariables,
} from './graphql/types/AddCheckoutPromoCode';
import { Checkout } from './graphql/types/Checkout';
import { CheckoutAddCredit, CheckoutAddCreditVariables } from './graphql/types/CheckoutAddCredit';
import { CheckoutDetails } from './graphql/types/CheckoutDetails';
import {
  CheckoutProductVariants,
  CheckoutProductVariants_productVariants,
  CheckoutProductVariantsVariables,
} from './graphql/types/CheckoutProductVariants';
import {
  CheckoutRemoveCredit,
  CheckoutRemoveCreditVariables,
} from './graphql/types/CheckoutRemoveCredit';
import { CompleteCheckout, CompleteCheckoutVariables } from './graphql/types/CompleteCheckout';
import { CreateCheckout, CreateCheckoutVariables } from './graphql/types/CreateCheckout';
import {
  CreateCheckoutPayment,
  CreateCheckoutPaymentVariables,
} from './graphql/types/CreateCheckoutPayment';
import { AddressInput, CountryCode, LanguageCodeEnum } from './graphql/types/globalTypes';
import { Payment } from './graphql/types/Payment';
import {
  RemoveCheckoutPromoCode,
  RemoveCheckoutPromoCodeVariables,
} from './graphql/types/RemoveCheckoutPromoCode';
import {
  RequestPasswordReset,
  RequestPasswordResetVariables,
} from './graphql/types/RequestPasswordReset';
import { SetPassword, SetPasswordVariables } from './graphql/types/SetPassword';
import { TokenAuth, TokenAuthVariables } from './graphql/types/TokenAuth';
import { TokenRefresh, TokenRefreshVariables } from './graphql/types/TokenRefresh';
import {
  UpdateCheckoutBillingAddress,
  UpdateCheckoutBillingAddressVariables,
} from './graphql/types/UpdateCheckoutBillingAddress';
import {
  UpdateCheckoutBillingAddressWithEmail,
  UpdateCheckoutBillingAddressWithEmailVariables,
} from './graphql/types/UpdateCheckoutBillingAddressWithEmail';
import {
  UpdateCheckoutLine,
  UpdateCheckoutLineVariables,
} from './graphql/types/UpdateCheckoutLine';
import {
  UpdateCheckoutShippingAddress,
  UpdateCheckoutShippingAddressVariables,
} from './graphql/types/UpdateCheckoutShippingAddress';
import {
  UpdateCheckoutShippingMethod,
  UpdateCheckoutShippingMethodVariables,
} from './graphql/types/UpdateCheckoutShippingMethod';
import { User } from './graphql/types/User';
import { UserCheckoutDetails } from './graphql/types/UserCheckoutDetails';
import { UserDetails } from './graphql/types/UserDetails';
import { VerifyToken, VerifyTokenVariables } from './graphql/types/VerifyToken';
import { WishlistProducts, WishlistProductsVariables } from './graphql/types/WishlistProducts';
import {
  WishlistSyncProducts,
  WishlistSyncProductsVariables,
} from './graphql/types/WishlistSyncProducts';
import { filterNotEmptyArrayItems } from './utils';

export enum PendingSaveItems {
  UPDATE_CART = 'updateCart',
  BILLING_ADDRESS = 'billingAddress',
  SHIPPING_ADDRESS = 'shippingAddress',
  SHIPPING_AS_BILLING_ADDRESS = 'shippingAsBillingAddress',
}

export interface ApolloErrorWithUserInput extends ApolloError {
  extraInfo: {
    userInputErrors?: unknown[];
  };
}

export interface IApolloClientManagerResponse<T> {
  data?: T;
  error?: ApolloErrorWithUserInput;
}

export interface VerifySignInTokenInput {
  token: string;
}

export interface RefreshSignInTokenInput {
  csrfToken?: string | null;
  refreshToken?: string;
}

export interface CreatePaymentInput {
  amount: number;
  checkoutId: string;
  gateway: string;
  billingAddress: CheckoutAddress;
  token?: string;
  returnUrl?: string;
}

export interface CompleteCheckoutInput {
  checkoutId: string;
  paymentData?: object;
  redirectUrl?: string;
  storeSource?: boolean;
}

export class ApolloClientManager {
  private client: ApolloClient<NormalizedCacheObject>;

  constructor(client?: ApolloClient<NormalizedCacheObject>, options?: ApolloOptions) {
    this.client = client ?? initializeApollo(null, options);
  }

  getClient() {
    return this.client;
  }

  subscribeToUserChange = (
    next: (value: User | null) => void,
    error?: (error: unknown) => void,
    complete?: () => void,
  ) => {
    this.client
      .watchQuery<UserDetails, unknown>({
        fetchPolicy: 'cache-only',
        query: getUserDetailsQuery,
      })
      .subscribe((value) => next(value.data?.me), error, complete);
  };

  getUser = async () => {
    const { data, errors } = await this.client.query<UserDetails, unknown>({
      fetchPolicy: 'network-only',
      query: getUserDetailsQuery,
    });

    if (errors?.length) {
      return {
        error: errors,
      };
    }
    return {
      data: data?.me,
    };
  };

  wishlistSyncProducts = async (productIds: string[], id?: string) => {
    const { data } = await this.client.mutate<WishlistSyncProducts, WishlistSyncProductsVariables>({
      fetchPolicy: 'no-cache',
      mutation: wishlistSyncProductsMutation,
      variables: {
        id,
        productIds,
      },
    });

    return {
      data: data?.wishlistSyncProducts?.wishlist,
    };
  };

  refreshWishlist = async (id: string, productIds?: string[]) => {
    if (productIds?.length > 0) {
      return this.wishlistSyncProducts(productIds, id);
    }

    const { data } = await this.client.query<WishlistProducts, WishlistProductsVariables>({
      fetchPolicy: 'network-only',
      query: wishlistProductsQuery,
      variables: {
        id,
      },
    });

    return {
      data: data?.wishlist,
    };
  };

  registerAccount = async (email: string, password: string) => {
    const { data, errors } = await this.client.mutate<AccountRegister, AccountRegisterVariables>({
      fetchPolicy: 'no-cache',
      mutation: accountRegisterMutation,
      variables: {
        email,
        password,
        shopUrl: process.env.NEXT_PUBLIC_SHOP_URL || '',
      },
    });

    if (errors?.length) {
      return {
        error: errors,
      };
    }
    if (data?.accountRegister?.accountErrors.length) {
      return {
        error: data.accountRegister.accountErrors,
      };
    }
    return {
      data: {
        requiresConfirmation: data?.accountRegister?.requiresConfirmation,
      },
    };
  };

  resetPasswordRequest = async (email: string, redirectUrl: string) => {
    const { data, errors } = await this.client.mutate<
      RequestPasswordReset,
      RequestPasswordResetVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: requestPasswordResetMutation,
      variables: {
        email,
        redirectUrl,
      },
    });

    if (errors?.length) {
      return {
        error: errors,
      };
    }
    if (data?.requestPasswordReset?.accountErrors.length) {
      return {
        error: data.requestPasswordReset.accountErrors,
      };
    }
    return {
      data,
    };
  };

  setPassword = async (email: string, token: string, password: string) => {
    const { data, errors } = await this.client.mutate<SetPassword, SetPasswordVariables>({
      fetchPolicy: 'no-cache',
      mutation: setPasswordMutation,
      variables: {
        email,
        token,
        password,
      },
    });

    if (errors?.length) {
      return {
        error: errors,
      };
    }
    if (data?.setPassword?.accountErrors.length) {
      return {
        error: data.setPassword.accountErrors,
      };
    }

    return {
      data,
    };
  };

  signIn = async (email: string, password: string) => {
    const { data, errors } = await this.client.mutate<TokenAuth, TokenAuthVariables>({
      fetchPolicy: 'no-cache',
      mutation: tokenAuthMutation,
      variables: {
        email,
        password,
      },
    });

    if (errors?.length) {
      return {
        error: errors,
      };
    }
    if (data?.tokenCreate?.errors.length) {
      return {
        error: data.tokenCreate.errors,
      };
    }
    return {
      data: {
        csrfToken: data?.tokenCreate?.csrfToken,
        token: data?.tokenCreate?.token,
        user: data?.tokenCreate?.user,
      },
    };
  };

  signOut = async () => {
    await this.client.resetStore();
  };

  verifySignInToken = async ({ token }: VerifySignInTokenInput) => {
    const { data, errors } = await this.client.mutate<VerifyToken, VerifyTokenVariables>({
      fetchPolicy: 'no-cache',
      mutation: tokenVeryficationMutation,
      variables: {
        token,
      },
    });

    if (errors?.length) {
      return {
        error: errors,
      };
    }
    if (data?.tokenVerify?.accountErrors.length) {
      return {
        error: data.tokenVerify.accountErrors,
      };
    }
    return {
      data: {
        isValid: data?.tokenVerify?.isValid,
        payload: data?.tokenVerify?.payload,
        user: data?.tokenVerify?.user,
      },
    };
  };

  refreshSignInToken = async ({ csrfToken, refreshToken }: RefreshSignInTokenInput) => {
    const { data, errors } = await this.client.mutate<TokenRefresh, TokenRefreshVariables>({
      fetchPolicy: 'no-cache',
      mutation: tokenRefreshMutation,
      variables: {
        csrfToken,
        refreshToken,
      },
    });

    if (errors?.length) {
      return {
        error: errors,
      };
    }
    if (data?.tokenRefresh?.accountErrors.length) {
      return {
        error: data.tokenRefresh.accountErrors,
      };
    }
    return {
      data: {
        token: data?.tokenRefresh?.token,
        user: data?.tokenRefresh?.user,
      },
    };
  };

  getCheckout = async (isUserSignedIn: boolean, checkoutToken: string | null) => {
    let checkout: Checkout | null;
    try {
      checkout = await new Promise((resolve, reject) => {
        if (isUserSignedIn) {
          const observable = this.client.watchQuery<UserCheckoutDetails, unknown>({
            fetchPolicy: 'network-only',
            query: userCheckoutDetails,
          });
          observable.subscribe(
            (result) => {
              const { data, errors } = result;
              if (errors?.length) {
                reject(errors);
              } else {
                resolve(data.me?.checkout);
              }
            },
            (error) => {
              reject(error);
            },
          );
        } else if (checkoutToken) {
          const observable = this.client.watchQuery<CheckoutDetails, unknown>({
            fetchPolicy: 'network-only',
            query: checkoutDetails,
            variables: {
              token: checkoutToken,
            },
          });
          observable.subscribe(
            (result) => {
              const { data, errors } = result;
              if (errors?.length) {
                reject(errors);
              } else {
                resolve(data.checkout);
              }
            },
            (error) => {
              reject(error);
            },
          );
        } else {
          resolve(null);
        }
      });

      if (checkout) {
        return {
          data: this.constructCheckoutModel(checkout),
        };
      }
    } catch (error) {
      return {
        error,
      };
    }
    return {};
  };

  getRefreshedCheckoutLines = async (checkoutlines: CheckoutLine[] | null) => {
    const idsOfMissingVariants = checkoutlines
      ?.filter((line) => !line.variant || !line.totalPrice)
      .map((line) => line.variant.id);
    const linesWithProperVariant =
      checkoutlines?.filter((line) => line.variant && line.totalPrice) || [];

    let variants: CheckoutProductVariants_productVariants | null | undefined;
    if (idsOfMissingVariants && idsOfMissingVariants.length) {
      try {
        const observable = this.client.watchQuery<
          CheckoutProductVariants,
          CheckoutProductVariantsVariables
        >({
          query: checkoutProductVariants,
          variables: {
            ids: idsOfMissingVariants,
          },
        });
        variants = await new Promise((resolve, reject) => {
          observable.subscribe(
            (result) => {
              const { data, errors } = result;
              if (errors?.length) {
                reject(errors);
              } else {
                resolve(data.productVariants);
              }
            },
            (error) => {
              reject(error);
            },
          );
        });
      } catch (error) {
        return {
          error,
        };
      }
    }

    const linesWithMissingVariantUpdated = variants
      ? variants.edges.map((edge) => {
          const existingLine = checkoutlines?.find((line) => line.variant.id === edge.node.id);
          const variantPricing = edge.node.pricing?.price;
          const totalPrice = variantPricing
            ? {
                gross: {
                  ...variantPricing.gross,
                  amount: variantPricing.gross.amount * (existingLine?.quantity || 0),
                },
                net: {
                  ...variantPricing.net,
                  amount: variantPricing.net.amount * (existingLine?.quantity || 0),
                },
              }
            : null;

          return {
            id: existingLine?.id,
            quantity: existingLine?.quantity || 0,
            totalPrice,
            variant: {
              attributes: edge.node.attributes,
              id: edge.node.id,
              isAvailable: edge.node.quantityAvailable > 0,
              name: edge.node.name,
              pricing: edge.node.pricing,
              product: edge.node.product,
              quantityAvailable: edge.node.quantityAvailable,
              sku: edge.node.sku,
            },
          };
        })
      : [];

    const linesWithProperVariantUpdated = linesWithProperVariant.map((line) => {
      const variantPricing = line.variant.pricing?.price;
      const totalPrice = variantPricing
        ? {
            gross: {
              ...variantPricing.gross,
              amount: variantPricing.gross.amount * line.quantity,
            },
            net: {
              ...variantPricing.net,
              amount: variantPricing.net.amount * line.quantity,
            },
          }
        : null;

      return {
        id: line.id,
        quantity: line.quantity,
        totalPrice,
        variant: line.variant,
      };
    });

    return {
      data: [...linesWithMissingVariantUpdated, ...linesWithProperVariantUpdated],
    };
  };

  createCheckout = async (
    email: string,
    lines: Array<{ variantId: string; quantity: number }>,
    shippingAddress?: AddressInput,
    billingAddress?: AddressInput,
    languageCode?: LanguageCodeEnum,
  ) => {
    try {
      const variables = {
        checkoutInput: {
          billingAddress,
          email,
          lines,
          shippingAddress,
          languageCode,
        },
      };
      const { data, errors } = await this.client.mutate<CreateCheckout, CreateCheckoutVariables>({
        mutation: createCheckoutMutation,
        variables,
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutCreate?.errors.length) {
        return {
          error: data?.checkoutCreate?.errors,
        };
      }
      if (data?.checkoutCreate?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutCreate.checkout),
        };
      }
    } catch (error) {
      return {
        error,
      };
    }
    return {};
  };

  setCartItem = async (checkoutId: string, lines: CheckoutModel['lines']) => {
    if (checkoutId && lines?.length) {
      const alteredLines = lines.map((line) => ({
        quantity: line.quantity,
        variantId: line.variant.id,
        data: [...line.data],
      }));

      try {
        const { data, errors } = await this.client.mutate<
          UpdateCheckoutLine,
          UpdateCheckoutLineVariables
        >({
          mutation: updateCheckoutLineMutation,
          variables: {
            checkoutId,
            lines: alteredLines,
          },
        });

        if (errors?.length) {
          return {
            error: errors,
          };
        }
        if (data?.checkoutLinesUpdate?.errors.length) {
          return {
            error: data?.checkoutLinesUpdate?.errors,
          };
        }
        if (data?.checkoutLinesUpdate?.checkout) {
          return {
            data: this.constructCheckoutModel(data.checkoutLinesUpdate.checkout),
          };
        }
      } catch (error) {
        return {
          error,
        };
      }
    }
    return {};
  };

  setShippingAddress = async (shippingAddress: AddressInput, email: string, checkoutId: string) => {
    try {
      const variables = {
        checkoutId,
        email,
        shippingAddress,
      };
      const { data, errors } = await this.client.mutate<
        UpdateCheckoutShippingAddress,
        UpdateCheckoutShippingAddressVariables
      >({
        mutation: updateCheckoutShippingAddressMutation,
        variables,
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutEmailUpdate?.errors.length) {
        return {
          error: data?.checkoutEmailUpdate?.errors,
        };
      }
      if (data?.checkoutShippingAddressUpdate?.errors.length) {
        return {
          error: data?.checkoutShippingAddressUpdate?.errors,
        };
      }
      if (data?.checkoutShippingAddressUpdate?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutShippingAddressUpdate.checkout),
        };
      }
      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  setBillingAddress = async (billingAddress: AddressInput, checkoutId: string) => {
    try {
      const variables = {
        billingAddress,
        checkoutId,
      };
      const { data, errors } = await this.client.mutate<
        UpdateCheckoutBillingAddress,
        UpdateCheckoutBillingAddressVariables
      >({
        mutation: updateCheckoutBillingAddressMutation,
        variables,
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutBillingAddressUpdate?.errors.length) {
        return {
          error: data?.checkoutBillingAddressUpdate?.errors,
        };
      }
      if (data?.checkoutBillingAddressUpdate?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutBillingAddressUpdate.checkout),
        };
      }
      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  setBillingAddressWithEmail = async (
    billingAddress: AddressInput,
    email: string,
    checkoutId: string,
  ) => {
    try {
      const variables = {
        billingAddress,
        checkoutId,
        email,
      };
      const { data, errors } = await this.client.mutate<
        UpdateCheckoutBillingAddressWithEmail,
        UpdateCheckoutBillingAddressWithEmailVariables
      >({
        mutation: updateCheckoutBillingAddressWithEmailMutation,
        variables,
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutEmailUpdate?.errors.length) {
        return {
          error: data?.checkoutEmailUpdate?.errors,
        };
      }
      if (data?.checkoutBillingAddressUpdate?.errors.length) {
        return {
          error: data?.checkoutBillingAddressUpdate?.errors,
        };
      }
      if (data?.checkoutBillingAddressUpdate?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutBillingAddressUpdate.checkout),
        };
      }
      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  setShippingMethod = async (shippingMethodId: string, checkoutId: string) => {
    try {
      const { data, errors } = await this.client.mutate<
        UpdateCheckoutShippingMethod,
        UpdateCheckoutShippingMethodVariables
      >({
        mutation: updateCheckoutShippingMethodMutation,
        variables: {
          checkoutId,
          shippingMethodId,
        },
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutShippingMethodUpdate?.errors.length) {
        return {
          error: data?.checkoutShippingMethodUpdate?.errors,
        };
      }
      if (data?.checkoutShippingMethodUpdate?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutShippingMethodUpdate.checkout),
        };
      }
      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  addPromoCode = async (promoCode: string, checkoutId: string) => {
    try {
      const { data, errors } = await this.client.mutate<
        AddCheckoutPromoCode,
        AddCheckoutPromoCodeVariables
      >({
        mutation: addCheckoutPromoCode,
        variables: { checkoutId, promoCode },
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutAddPromoCode?.errors.length) {
        return {
          error: data?.checkoutAddPromoCode?.errors,
        };
      }
      if (data?.checkoutAddPromoCode?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutAddPromoCode.checkout),
        };
      }
      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  removePromoCode = async (promoCode: string, checkoutId: string) => {
    try {
      const { data, errors } = await this.client.mutate<
        RemoveCheckoutPromoCode,
        RemoveCheckoutPromoCodeVariables
      >({
        mutation: removeCheckoutPromoCode,
        variables: { checkoutId, promoCode },
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutRemovePromoCode?.errors.length) {
        return {
          error: data?.checkoutRemovePromoCode?.errors,
        };
      }
      if (data?.checkoutRemovePromoCode?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutRemovePromoCode.checkout),
        };
      }
      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  checkoutAddCredit = async (checkoutId: string) => {
    try {
      const { data, errors } = await this.client.mutate<
        CheckoutAddCredit,
        CheckoutAddCreditVariables
      >({
        mutation: checkoutAddCredit,
        variables: {
          checkoutId,
        },
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }

      if (data?.checkoutAddCredit?.errors.length) {
        return {
          error: data?.checkoutAddCredit?.errors,
        };
      }

      if (data?.checkoutAddCredit?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutAddCredit.checkout),
        };
      }

      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  checkoutRemoveCredit = async (checkoutId: string) => {
    try {
      const { data, errors } = await this.client.mutate<
        CheckoutRemoveCredit,
        CheckoutRemoveCreditVariables
      >({
        mutation: checkoutRemoveCredit,
        variables: {
          checkoutId,
        },
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }

      if (data?.checkoutRemoveCredit?.errors.length) {
        return {
          error: data?.checkoutRemoveCredit?.errors,
        };
      }

      if (data?.checkoutRemoveCredit?.checkout) {
        return {
          data: this.constructCheckoutModel(data.checkoutRemoveCredit.checkout),
        };
      }

      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  createPayment = async ({
    amount,
    checkoutId,
    gateway,
    billingAddress,
    token,
    returnUrl,
  }: CreatePaymentInput) => {
    try {
      const variables = {
        checkoutId,
        paymentInput: {
          amount,
          billingAddress: this.constructAddressModel(billingAddress),
          gateway,
          returnUrl,
          token,
        },
      };
      const { data, errors } = await this.client.mutate<
        CreateCheckoutPayment,
        CreateCheckoutPaymentVariables
      >({
        mutation: createCheckoutPaymentMutation,
        variables,
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutPaymentCreate?.errors.length) {
        return {
          error: data?.checkoutPaymentCreate?.errors,
        };
      }
      if (data?.checkoutPaymentCreate?.payment) {
        return {
          data: {
            ...data?.checkoutPaymentCreate,
            payment: this.constructPaymentModel(data.checkoutPaymentCreate.payment),
          },
        };
      }
      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  completeCheckout = async ({ checkoutId, paymentData }: CompleteCheckoutInput) => {
    try {
      const paymentDataString = paymentData && JSON.stringify(paymentData);

      const { data, errors } = await this.client.mutate<
        CompleteCheckout,
        CompleteCheckoutVariables
      >({
        mutation: completeCheckoutMutation,
        variables: {
          checkoutId,
          paymentData: paymentDataString,
        },
      });

      if (errors?.length) {
        return {
          error: errors,
        };
      }
      if (data?.checkoutComplete?.errors.length) {
        return {
          error: data?.checkoutComplete?.errors,
        };
      }
      if (data?.checkoutComplete) {
        return {
          data: data.checkoutComplete,
        };
      }
      return {};
    } catch (error) {
      return {
        error,
      };
    }
  };

  private constructCheckoutModel = ({
    id,
    token,
    email,
    shippingAddress,
    billingAddress,
    discount,
    discountName,
    voucherCode,
    lines,
    availablePaymentGateways,
    availableShippingMethods,
    shippingMethod,
    giftCards,
    credit,
    canShowVoucherField,
  }: Checkout): CheckoutModel => ({
    availablePaymentGateways,
    availableShippingMethods: availableShippingMethods
      ? availableShippingMethods.filter(filterNotEmptyArrayItems)
      : [],
    billingAddress,
    email,
    id,
    lines: lines
      ?.filter((item) => item?.quantity && item.variant.id)
      .map((item) => {
        const itemVariant = item?.variant;

        return {
          id: item.id,
          quantity: item.quantity,
          totalPrice: item?.totalPrice,
          variant: {
            attributes: itemVariant?.attributes,
            id: itemVariant.id,
            isAvailable: itemVariant?.quantityAvailable > 0,
            name: itemVariant?.name,
            pricing: itemVariant?.pricing,
            product: itemVariant?.product,
            quantityAvailable: itemVariant?.quantityAvailable,
            sku: itemVariant?.sku,
          },
          data: item?.data,
        };
      }),
    promoCodeDiscount: {
      discount,
      discountName,
      voucherCode,
    },
    credit,
    giftCards,
    shippingAddress,
    shippingMethod,
    token,
    canShowVoucherField,
  });

  private constructPaymentModel = ({
    id,
    gateway,
    token,
    extraData,
    total,
  }: Payment): PaymentModel => {
    let data;
    try {
      data = JSON.parse(extraData);
    } catch (e) {}

    return {
      extraData: data,
      gateway,
      id,
      token,
      total,
    };
  };

  private constructAddressModel = (address: CheckoutAddress): AddressInput => ({
    city: address.city,
    country: CountryCode[address.country?.code],
    countryArea: address.countryArea,
    firstName: address.firstName,
    lastName: address.lastName,
    phone: address.phone,
    postalCode: address.postalCode,
    streetAddress1: address.streetAddress1,
    streetAddress2: address.streetAddress2,
  });
}
