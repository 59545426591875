/* eslint-disable @typescript-eslint/no-explicit-any */
import { keyframes } from '@chakra-ui/system';
import { getColor, mode } from '@chakra-ui/theme-tools';

const fade = (startColor: string, endColor: string) =>
  keyframes({
    from: { borderColor: startColor, background: startColor },
    to: { borderColor: endColor, background: endColor },
  });

const baseStyle = (props: Record<string, any>) => {
  const defaultStartColor = mode('darkGray.200', 'darkGray.800')(props);
  const defaultEndColor = mode('darkGray.300', 'darkGray.600')(props);

  const { startColor = defaultStartColor, endColor = defaultEndColor, theme } = props;

  const start = getColor(theme, startColor);
  const end = getColor(theme, endColor);

  return {
    opacity: 0.7,
    borderRadius: 'none',
    borderColor: start,
    background: end,
    animation: `0.5s linear infinite alternate ${fade(start, end)}`,
  };
};

export default {
  baseStyle,
};
